<template>
  <div class="space-y-16">
    <div class="">
      <div class="">
        <div class="pb-4 md:pb-0 px-2 md:px-0">
          <div class="text-lg font-medium text-gray-900">
            Review
          </div>
          <div class="mt-1 text-gray-600 text-sm">
            <div
                v-if="foundClients === null && !hasErrors"
                class="w-8 h-8"
            >
              <loader />
            </div>

            <div
                v-if="hasErrors"
                class="flex justify-center"
            >
              <div class="notification-box is-danger w-full max-w-3xl">
                <div class="font-bold pb-2">
                  Your audience has issues.
                </div>
                <p>
                  Please change the audience in the previous steps, and try again.
                </p>
              </div>
            </div>

            <automation-form-review-recap
                v-if="foundClients !== null"
                :automation="automation"
                :found-clients="foundClients"
            />

            <div
                v-if="!importDone"
                class="flex justify-center"
            >
              <div class="notification-box is-warning w-full max-w-3xl">
                <div class="font-bold pb-2">
                  Still importing data!
                </div>
                <p>
                  The initial import of your studio is running. You can save this automation, but it will not
                  be active. Once the import is completed, you will be able to enable it in the Automations
                  page
                </p>
              </div>
            </div>

            <div
                v-if="!outgoingSet"
                class="flex justify-center"
            >
              <div class="notification-box is-warning w-full max-w-3xl">
                <div class="font-bold pb-2">
                  Outgoing settings incomplete
                </div>
                <p>
                  Your outgoing message settings are incomplete.<br>
                  Once the outgoing message settings are updated, you can turn on this automation.
                </p>
                <router-link
                    :to="{name: routeList.studio.settings.profile}"
                    class="text-link mt-3 font-medium block"
                    target="_blank"
                >
                  Update your settings
                </router-link>
              </div>
            </div>

            <div
                v-if="viaSms && foundClients >= warningForSms && foundClients < maxForSms"
                class="flex justify-center"
            >
              <div class="notification-box is-warning w-full max-w-3xl">
                <div class="font-bold pb-2">
                  Your current segment contains more than {{ warningForSms }} clients.
                </div>
                <p>
                  Are you sure you want to send an SMS to this many people?
                </p>
                <p>
                  We suggest changing the automation action to
                  email, which will prevent
                  customers from becoming annoyed after receiving too many text messages.
                </p>
              </div>
            </div>

            <div
                v-if="viaSms && foundClients >= maxForSms"
                class="flex justify-center"
            >
              <div class="notification-box is-danger w-full max-w-3xl">
                <div class="font-bold pb-2">
                  Your current audience is too large for a one time mass text.
                </div>
                <p>
                  To prevent SPAM and remain TCPA compliant, please change your automation type to Email.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
        v-if="!hasErrors"
        class="split-view-left"
    >
      <div class="pb-4 md:pb-0 md:w-1/3">
        <div class="text-lg font-medium text-gray-900">
          Audience example
        </div>
        <div class="mt-1 text-gray-600 text-sm space-y-5">
          <p>Double check your audience is correctly setup using the audience example to the right.</p>
          <p>
            <span class="font-bold">Note:</span> Clients that have opted-out from this automation communication type
                                                 will not appear in the audience example.
          </p>
        </div>
      </div>

      <div class="block flex-1">
        <div class="rounded-lg shadow-md bg-white">
          <div
              v-if="loading"
              class="h-48 w-full flex items-center justify-center"
          >
            <div class="w-12 h-12 text-gray-300">
              <loader />
            </div>
          </div>
        </div>

        <div class="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
          <table class="table divide-y">
            <thead>
            <tr>
              <table-th>Client</table-th>
              <table-th>Club Count</table-th>
              <table-th>Last Attended</table-th>
            </tr>
            </thead>
            <tbody
                v-if="!loading && clients && clients.length > 0"
                class="bg-white divide-y divide-gray-200"
            >
            <automation-form-audience-preview-row
                v-for="client in clients"
                :key="client.id"
                :client="client"
            />
            </tbody>
            <tbody
                v-if="!loading && clients && clients.length === 0"
                class="bg-white divide-y divide-gray-200"
            >
            <tr>
              <td colspan="4">
                <div class="p-12 text-center">
                  <p class="text-gray-500">
                    {{ emptyMessage }}
                  </p>
                </div>
              </td>
            </tr>
            </tbody>
            <tr v-if="paginatorInfo && paginatorInfo.total > 0&& paginatorInfo.lastPage > 1">
              <td colspan="4">
                <div class="w-full py-2 text-right px-4 rounded-b">
                  <pagination
                      v-if="paginatorInfo && paginatorInfo.lastPage > 1"
                      :pagination="paginatorInfo"
                      class="flex items-center justify-between text-gray-700 text-sm"

                      @setPage="page = $event"
                  >
                    <div
                        v-if="loading"
                        class="inline-block w-8 h-8 mr-2 text-gray-400"
                    >
                      <loader />
                    </div>
                  </pagination>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div
        v-if="!hasErrors"
        class="split-view-left"
    >
      <div class="pb-4 md:pb-0 md:w-1/3">
        <div class="text-lg font-medium text-gray-900">
          Save
        </div>
        <div class="mt-1 text-gray-600 text-sm space-y-5">
          <div
              v-if="!automation.one_off"
              class="space-y-5"
          >
            <p>Make sure to preview the content of your automation before activating.</p>
            <div>
              <p>Depending on this automation's start time, upon clicking save your automation will:</p>

              <ul class="mt-2 leading-relaxed">
                <li><strong>Activate Immediately</strong>: Activate within the next few minutes</li>
                <li><strong>After/date time</strong>: Schedule to activate after set start time</li>
                <li><strong>Off</strong>: Remain paused until manually activated</li>
              </ul>
            </div>
          </div>

          <div
              v-if="automation.one_off"
              class="space-y-5"
          >
            <p>Make sure to preview the content of your automation before activating.</p>
            <p>
              This one-time-only automation will trigger for each client in your current audience, and cannot be
              activated again.
            </p>
            <div>
              <p>Depending on this automation's start time, upon clicking save your automation will:</p>

              <ul class="mt-2 leading-relaxed">
                <li>
                  <strong>Activate Immediately</strong>: Deploy to the current audience within the next few minutes.
                </li>
                <li><strong>After/date time</strong>: Schedule to deploy after set start time</li>
                <li><strong>Off</strong>: Remain paused until manually activated</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="block flex-1 mt-8">
        <div class="bg-white shadow p-4 rounded-lg">
          <v-button
              :disabled="!isValid || saving"
              :loading="saving"
              class="btn btn-green px-8"
              @click="save"
          >
            {{
              saveButtonText
            }}
          </v-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>

import { QLAutomation }                      from "@/graphql/queries/ql/composables";
import { SparkErrors }                       from "@/classes/utils/SparkFormErrors";
import TableTh                               from "@/components/layout/Tables/TableTh.vue";
import AutomationFormAudiencePreviewRow      from "@/components/automations/Create/partials/AutomationFormAudiencePreviewRow.vue";
import Loader                                from "@/components/layout/Loader.vue";
import Pagination                            from "@/components/layout/Pagination.vue";
import AutomationFormReviewRecap             from "@/components/automations/Create/partials/AutomationFormReviewRecap.vue";
import VButton                               from "@/components/layout/VButton.vue";
import currentTeamQuery                      from "@/composables/queries/currentTeamQuery";
import { computed, reactive, toRef, toRefs } from "vue";
import AutomationDecorator                   from "@/composables/decorators/AutomationDecorator";
import SegmentDecorator                      from "@/composables/decorators/SegmentDecorator";
import { previewSegment }                    from "@/components/automations/Create/composables/previewSegment";
import routesList                            from "@/composables/routesList";

interface Props {
  automation: QLAutomation;
  errors: SparkErrors;
  saving: boolean;
}

const props = withDefaults(defineProps<Props>(), {});
const emit = defineEmits([ "save" ]);

const { currentTeam } = currentTeamQuery();
const importDone = computed(() => {
  return currentTeam.value && currentTeam.value.first_import_done;
});
const outgoingSet = computed(() => {
  return currentTeam.value && currentTeam.value.outgoing_set;
});

const intData = reactive({
                           warningForSms: 50,
                           maxForSms:     500,
                           hasErrors:     false,
                           page:          1
                         });

const automation = toRef(props, "automation");

const viaSms = AutomationDecorator(automation).computed.via_sms;

const segmentToPost = computed(() => {
  return SegmentDecorator(automation.value.segment).computed.toPost;
});

const {
  clients,
  paginatorInfo,
  foundClients,
  loading
} = previewSegment(intData, segmentToPost);

const isValid = computed(() => {
  let t = !loading.value && !intData.hasErrors;

  if (viaSms && foundClients.value >= intData.maxForSms) {
    t = false;
  }
  return t;
});

const saveButtonText = computed(() => {
  if (automation.value.one_off) {
    if (automation.value.active) {
      return "Save & Run now!";
    }
  }
  if (automation.value.active) {
    return "Save & Activate";
  }

  if (automation.value.start_at != null) {
    return "Save & Wait";
  }

  return "Save";
});

const emptyMessage = computed(() => {
  if (automation.value.one_off) {
    return "No clients will be contacted! Modify your audience by expanding the filters.";
  }

  return "No clients will be contacted right now. As soon as the filters will be met, we will send them this communication";
});
const save = () => {
  emit("save", automation.value);
};

const { page, hasErrors, warningForSms, maxForSms } = toRefs(intData);
const { routeList } = routesList();

</script>
