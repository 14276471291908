<template>
  <div>
    <timeframe-selector
        v-model:date="dateSelected"
        position="left"
        :min-date="automation.created_at"
        class="mb-4" />
    <div class="space-y-3 lg:space-y-6">
      <div class="grid lg:grid-cols-3 gap-3 lg:gap-6 grid-flow-row-dense">
        <div class="flex flex-col rounded-lg shadow overflow-hidden">
          <automation-stat-box-simple
              :current="automationStats.created"
              :loading="loadingStats"
              :past="automationStatsPast.created"
              :show-past="showPastData">
            <template #icon>
              <svg-icon
                  name="todo"
                  class="w-5 h-5" />
            </template>
            <template #title>
              To-Dos Created
            </template>
          </automation-stat-box-simple>
        </div>
        <div class="flex flex-col rounded-lg shadow overflow-hidden">
          <automation-stat-box-simple
              :current="automationStats.postponed"
              :loading="loadingStats"
              :past="automationStatsPast.postponed"
              :show-past="showPastData">
            <template #icon>
              <svg-icon
                  name="timer"
                  class="w-5 h-5" />
            </template>
            <template #title>
              To-Dos Postponed
            </template>
          </automation-stat-box-simple>
        </div>
        <div class="flex flex-col rounded-lg shadow overflow-hidden">
          <automation-stat-box-simple
              :current="completedRatePercentage"
              :loading="loadingStats"
              :past="pastCompletedRatePercentage"
              :show-past="showPastData">
            <template #icon>
              <svg-icon
                  name="chartPie"
                  class="w-5 h-5" />
            </template>
            <template #title>
              To-Dos Completion Rate
            </template>
            <template #unit>
              %
            </template>
          </automation-stat-box-simple>
        </div>
      </div>

      <div class="grid gap-3 lg:grid-cols-2 lg:gap-6 grid-flow-row-dense">
        <div class="flex flex-col rounded-lg shadow overflow-hidden">
          <div class="flex items-center grow bg-white p-3 py-5">
            <automation-show-pie-chart
                :loading="loadingStats"
                :stats="chartCompletedRate"
                chart-label="Opened vs Unread">
              To-Do Completion Rate
            </automation-show-pie-chart>
          </div>
        </div>
        <div class="flex flex-col rounded-lg shadow overflow-hidden">
          <div class="flex items-center grow bg-white p-3 py-5">
            <automation-show-pie-chart
                :loading="loadingStats"
                :stats="chartDeletedRate"
                chart-label="Opened vs Unread">
              To-Do Deleted Rate
            </automation-show-pie-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import TimeframeSelector                                                from "@/components/layout/TimeframeSelector.vue";
import AutomationStatBoxSimple                                          from "@/components/layout/StatBoxSimple.vue";
import AutomationShowPieChart                                           from "@/components/automations/Show/partials/AutomationShowPieChart.vue";
import { computed, toRef }                                              from "vue";
import automationShowInfoDateSelection                                  from "@/components/automations/Show/Infos/automationShowInfoDateSelection";
import SvgIcon                                                          from "@/components/SvgIcon.vue";
import { QLFragmentFullAutomationFragment, useAutomationStatTodoQuery } from "@/graphql/queries/ql/composables";
import { PieChartData }                                                 from "@/components/layout/Charts/PieChartData";

interface Props {
  automation: QLFragmentFullAutomationFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits([]);

const automation = toRef(props, "automation");

const infoDate = automationShowInfoDateSelection(automation);

const { showPastData, dateSelected } = infoDate;

const currentPeriod = useAutomationStatTodoQuery(infoDate.queryVariablesCurrent, infoDate.queryOptionsCurrent);
const pastPeriod = useAutomationStatTodoQuery(infoDate.queryVariablesPast, infoDate.queryOptionsPast);

const blankObject = () => ({
  created:   0,
  completed: 0,
  open:      0,
  postponed: 0,
  deleted:   0
});

const currentPeriodResult = computed(() => currentPeriod.result.value?.automation_stat_todo ?? blankObject());

const pastPeriodResult = computed(() => pastPeriod.result.value?.automation_stat_todo ?? blankObject());

const loadingStats = computed((): boolean => {
  return currentPeriod.loading.value || pastPeriod.loading.value;
});

const automationStats = computed(() => {
  if (!currentPeriodResult.value) {
    return blankObject();
  }
  return currentPeriodResult.value;
});

const automationStatsPast = computed(() => {
  if (!pastPeriodResult.value) {
    return blankObject();
  }
  return pastPeriodResult.value;
});

const chartCompletedRate = computed((): PieChartData[] => {
  if (loadingStats.value == true) {
    return [];
  }
  return [
    { name: "Completed", cc: automationStats.value.completed },
    { name: "Created", cc: automationStats.value.created }
  ];
});

const chartDeletedRate = computed((): PieChartData[] => {
  if (loadingStats.value == true) {
    return [];
  }
  return [
    { name: "Deleted", cc: automationStats.value.deleted },
    { name: "Created", cc: automationStats.value.created }
  ];
});

const completedRatePercentage = computed(() => {
  if (!automationStats.value.created || !automationStats.value.completed) {
    return 0;
  }

  return Math.round((automationStats.value.completed / automationStats.value.created) * 1000) / 10;
});

const pastCompletedRatePercentage = computed(() => {
  if (!automationStatsPast.value.created || !automationStatsPast.value.completed) {
    return 0;
  }

  return Math.round((automationStatsPast.value.completed / automationStatsPast.value.created) * 1000) / 10;
});

</script>
