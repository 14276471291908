<template>
  <page-title title="Email Templates" section="Automations" sub-text="Create branded email designs without creating full automations! Start creating your own email design library for easier, faster automation setup." />
  <div class="container mx-auto grow">
    <div
        v-if="loading"
        class="w-full h-48 flex items-center justify-center">
      <loader class="w-10 h-10 text-gray-400" />
    </div>

    <div
        v-if="!loading"
        class="md:flex">
      <div class="w-lg shrink-0 max-w-lg mb-6 md:mb-0">
        <div>
          <span class="uppercase text-sm text-gray-600 mb-1">Templates</span>

          <ul class="space-y-1 mt-2">
            <li
                class="menu-item "
                :class="{'selected': currentCategory === '_all'}"
                @click="currentCategory = '_all'">
              All
            </li>
            <li
                v-for="category in categories"
                :key="category"
                class="menu-item"
                :class="{'selected': currentCategory === category}"
                @click="currentCategory = category">
              {{ category }}
            </li>
          </ul>
        </div>

        <div class="mt-8">
          <span class="uppercase text-sm text-gray-600 mb-1">Your templates</span>
          <ul class="space-y-1 mt-2">
            <li
                class="menu-item"
                :class="{'selected': currentCategory === '_past'}"
                @click="currentCategory = '_past'">
              Previously used emails
            </li>
            <li
                class="menu-item"
                :class="{'selected': currentCategory === '_manual'}"
                @click="currentCategory = '_manual'">
              Your creations
            </li>
          </ul>
        </div>
      </div>

      <div class="grow md:ml-8">
        <div
            v-if="emailsToDisplay.length === 0"
            class="h-full grow flex items-center justify-center">
          <div class="px-8 max-w-lg text-gray-500 text-sm font-medium">
            <div
                v-if="currentCategory === '_past'"
                class="space-y-6">
              <p>
                Once you create your first email automation,
                you can re-use the
                email design to quickly create new email
                automations.
              </p>
              <p>
                All of your email automations will create a template that will be available in this section “Previously
                used emails” for faster automation setup.
              </p>
            </div>
            <span v-if="currentCategory === '_manual'">Start building your custom email design library! For faster template creation, start a new template using the design from an existing email automation, or start from scratch.</span>
          </div>
        </div>
        <div v-if="emailsToDisplay.length > 0">
          <div class="flex justify-end">
            <div class="py-2">
              <input type="text" class="form-input" placeholder="Search..." v-model="filterText">
            </div>
          </div>
          <div class="mt-8 py-8 px-8 text-gray-700 text-sm font-medium bg-gray-100 rounded border border-gray-300" v-if="filteredEmails.length === 0">
            No email templates found for <strong>{{filterText}}</strong>
          </div>
          <div
              class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 grid-wrap" v-if="filteredEmails.length > 0">
            <div
                v-for="emailTemplate in filteredEmails"
                :key="emailTemplate.id"
                class="rounded-lg overflow-hidden shadow-md bg-white ">
              <div class="flex flex-col h-full">
                <div class="grow">
                  <img
                      :src="emailTemplate.thumbnail"
                      alt=""
                      class="h-48 w-full object-cover">
                  <div class="px-4 py-4">
                    <span class="text-lg">{{ emailTemplate.name }}</span>
                  </div>
                </div>
                <div>
                  <div class="flex items-center justify-between w-full border-t py-2 px-2 bg-gray-100">
                    <div class="px-2">
                      <router-link
                          v-if="emailTemplate.team_id"
                          :to="{name: routeList.automations.email_templates.show, params:{'id': emailTemplate.id}}"
                          class="text-sm font-medium text-blue-900 hover:text-blue-600">
                        View
                      </router-link>
                    </div>
                    <div class="px-2">
                      <router-link
                          :to="{name: routeList.automations.email_templates.create, params:{'id': emailTemplate.id}}"
                          class="text-sm font-medium text-blue-900 hover:text-blue-600">
                        Use as new template
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.menu-item {
  @apply px-4 py-2 rounded-lg text-gray-800 ;

  &.selected {
    @apply bg-gray-200 font-medium cursor-default;
  }

  &:hover {
    @apply bg-blue-100 cursor-pointer text-blue-800;
  }
}

</style>

<script lang="ts" setup>
import FuzzySearch                                                       from "fuzzy-search";
import Loader                                                            from "@/components/layout/Loader.vue";
import { QLFragrmentSmallEmailTemplateFragment, useEmailTemplatesQuery } from "@/graphql/queries/ql/composables";
import { computed, ref }                                                 from "vue";
import emailTemplateDecorator                                            from "@/composables/decorators/emailTemplateDecorator";
import routesList                                                        from "@/composables/routesList";
import PageTitle                                                         from "@/components/navbar/PageTitle.vue";

const emailTemplateQ = useEmailTemplatesQuery();

const {loading} = emailTemplateQ;
const {routeList} = routesList();

const emailTemplates = computed(() => emailTemplateQ.result.value?.email_templates ?? []);
const currentCategory = ref("_all");

const filterText = ref('');

const categories = computed(() => {
  return Array.from(new Set(emailTemplates.value.map((i) => i.category).filter(i => i != null && i.length > 0)));
});


const emailsToDisplay = computed((): Array<QLFragrmentSmallEmailTemplateFragment> => {
  if (currentCategory.value === "_all") {
    // all but manual
    return emailTemplates.value.filter(email => {
      let d = emailTemplateDecorator(email);
      return !d.computed.isPreviousEmail && !d.computed.isCustomEmail;
    });
  }

  if (currentCategory.value === "_manual") {
    return emailTemplates.value.filter(email => {
      let d = emailTemplateDecorator(email);
      return d.computed.isCustomEmail;
    });
  }
  if (currentCategory.value === "_past") {
    return emailTemplates.value.filter(email => {
      let d = emailTemplateDecorator(email);
      return d.computed.isPreviousEmail;
    });
  }

  return emailTemplates.value.filter(email => email.category === currentCategory.value);
});

const filteredEmails = computed(() => {
  const searcher = new FuzzySearch(emailsToDisplay.value, ['name'], {sort: true});
  return searcher.search(filterText.value)
})

</script>
