<template>
  <div>
    <div
        v-if="loading"
        class="flex items-center justify-center p-16 ">
      <div class="w-16 h-16 text-gray-400">
        <loader />
      </div>
    </div>
    <div
        v-if="formInstance"
        class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          {{ formInstance.form.name }}
        </h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
          Completion instance
        </p>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl class="sm:divide-y sm:divide-gray-200 ">
          <div
              v-if="formInstance.form.category"
              class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
              Category
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {{ formInstance.form.category }}
            </dd>
          </div>
          <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
              Completed on
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <timestamp-to-date
                  :timestamp="formInstance.created_at"
                  has-time
                  natural-date />
            </dd>
          </div>
          <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
              IP Address
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {{ formInstance.ip_address }}
            </dd>
          </div>
          <div
              v-if="formInstance.form.is_legal && formInstance.pdf_url"
              class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
              Files
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <ul
                  class="border border-gray-200 rounded-md divide-y divide-gray-200"
                  role="list">
                <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                  <div class="w-0 flex-1 flex items-center">
                    <svg-icon
                        class="flex-shrink-0 h-5 w-5 text-gray-400"
                        name="pdf-duotone" />
                    <span class="ml-2 flex-1 w-0 truncate"> compiled-form.pdf </span>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <a
                        :href="formInstance.pdf_url"
                        class="font-medium text-indigo-600 hover:text-indigo-500"
                        target="_blank"> Download </a>
                  </div>
                </li>
              </ul>
            </dd>
          </div>
        </dl>
        <dl class="border-t border-t-gray-200 border-t-4 sm:divide-y sm:divide-gray-200 ">
          <div
              v-for="answer in answers"
              :key="answer.id"
              class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
              {{ answer.question }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span v-if="answer.kind !== formFieldKinds.MULTIPLE && answer.kind !== formFieldKinds.YES_NO && answer.kind !== formFieldKinds.SIGNATURE">{{
                  answer.answer
                                                                                                                                                        }}</span>
              <ul v-if="answer.kind === formFieldKinds.MULTIPLE">
                <li v-for="singleAnswer in answer.answer" :key="singleAnswer">
                  {{ singleAnswer }}
                </li>
              </ul>
              <span
                  v-if="answer.kind === formFieldKinds.YES_NO"
                  :class="[answer.answer ? 'bg-green-100 text-green-800': 'bg-gray-100 text-gray-800']"
                  class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium">{{
                  answer.answer
                  ? "Yes"
                  : "No"
                                                                                                }}</span>

              <span
                  v-if="answer.kind === formFieldKinds.SIGNATURE"
                  :class="[answer.answer ? 'bg-green-100 text-green-800': 'bg-gray-100 text-gray-800']"
                  class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium">{{
                  answer.answer
                  ? "Signed"
                  : "Not signed"
                                                                                                }}</span>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { QLClient, QLFieldTypeEnum, QLFormField, useFormInstanceQuery } from "@/graphql/queries/ql/composables";
import { computed, toRef }                                              from "vue";
import TimestampToDate                                                  from "@/components/layout/TimestampToDate.vue";
import SvgIcon                                                          from "@/components/SvgIcon.vue";
import { deepCopy }                                                     from "@/classes/helpers/DeepCopy";
import Loader                                                           from "@/components/layout/Loader.vue";
import { findIndex, groupBy, keysIn }                                   from "lodash";

interface Props {
  client: QLClient;
  formInstanceId: string;
}

const props = withDefaults(defineProps<Props>(), {});

const client = toRef(props, "client");
const formInstanceId = toRef(props, "formInstanceId");

const formInstanceQ = useFormInstanceQuery(() => ({ id: formInstanceId.value }));

const { loading } = formInstanceQ;

formInstanceQ.onError((err) => console.log(err));

const formInstance = computed(() => formInstanceQ.result.value?.form_instance ?? null);

interface QuestionAndAnswer {
  id: string,
  question: string,
  kind: QLFieldTypeEnum,
  answer: string | string[] | boolean,
  position: number
}

const formFieldKinds = QLFieldTypeEnum;

const answers = computed((): QuestionAndAnswer[] => {
  if (!formInstance.value) {
    return [];
  }
  const data = groupBy(deepCopy(formInstance.value.responses), (i) => i.question);
  return keysIn(data).map((key) => {

    const question: QLFormField = formInstance.value.form.content
                                              .find((i: QLFormField) => {
                                                return i.id === key;
                                              });

    const ret = {
      id:       key,
      kind:     question.kind,
      question: "",
      answer:   null,
      position: findIndex(formInstance.value.form.content, (o) => o.id === key)
    };
    if (question) {
      ret.question = question.title;
    }
    const item = data[key];

    if (question.kind === QLFieldTypeEnum.MULTIPLE) {
      ret.answer = item.map((ans) => JSON.parse(ans.answer));
    } else {
      ret.answer = JSON.parse(item[0].answer);
    }
    return ret;
  }).sort((a, b) => a.position > b.position ? 1 : -1);

});

</script>
