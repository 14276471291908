<template>
  <div class="">
    <div class="bg-white rounded-lg shadow w-full min-h-[20em] h-full overflow-hidden ">
      <div class="flex flex-col grow justify-between ">
        <div class="px-5 pt-5">
          <h3 class="font-semibold text-gray-600 mb-2">
            {{ iProps.title.value }}
          </h3>
        </div>
        <div
            v-if="!loading && cleanResults.length == 0"
            class="p-2 pb-4 px-4 h-full">
          <empty class="h-full py-24">
            <template #title>
              No results yet
            </template>
            <template #subtitle>
              This form has not received any submissions yet.
            </template>
          </empty>
        </div>
        <div
            v-if="loading || cleanResults.length > 0"
            class="px-2 w-full grow flex items-center justify-center">
          <div class="h-48 w-full">
            <div
                v-if="loading"
                class="animate-loading w-full flex items-center justify-center h-full">
              <div class="flex justify-center">
                <div class="loading-bg h-20 w-20 rounded-full" />
              </div>
              <div class="flex justify-center ml-4">
                <div class="flex flex-col space-y-4">
                  <div class="inline-flex space-x-2">
                    <div class="loading-bg h-6 w-6 rounded" />
                    <div class="loading-bg h-6 w-24 rounded" />
                  </div>
                  <div class="inline-flex space-x-2">
                    <div class="loading-bg h-6 w-6 rounded" />
                    <div class="loading-bg h-6 w-24 rounded" />
                  </div>
                </div>
              </div>
            </div>
            <pie-chart
                v-if="cleanResults.length > 0"
                :items="cleanResults"
                label="answer"
                item-name="answer"
                class="h-full relative" />
          </div>
        </div>
        <div
            v-if="hasMoreToSee"
            class="w-full">
          <div class="flex justify-end px-4 py-6">
            <div
                class="text-link text-sm font-medium flex items-center space-x-1"
                @click="moreOpen = !moreOpen">
              <span v-text="moreOpen ? 'Collapse': 'View all'" />
              <div
                  class="transform transition-all"
                  :class="{'rotate-180': moreOpen}">
                <svg-icon
                    name="down"
                    class="h-4 w-4" />
              </div>
            </div>
          </div>
          <div
              v-if="moreOpen"
              class="flex flex-col">
            <div class="overflow-x-auto">
              <div class="inline-block min-w-full align-middle">
                <div class="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5">
                  <table class="min-w-full divide-y divide-gray-300">
                    <thead class="bg-gray-50">
                    <tr>
                      <th
                          scope="col"
                          class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Answer
                      </th>
                      <th
                          scope="col"
                          class="px-4 py-3.5 text-right text-sm font-semibold text-gray-900">
                        Count
                      </th>
                    </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200">
                    <tr
                        v-for="lineItem in moreDataInTable"
                        :key="lineItem.answer">
                      <td class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {{ lineItem.answer }}
                      </td>
                      <td class="whitespace-nowrap px-4 py-4 text-sm text-gray-500 text-right font-mono">
                        {{ lineItem.cc }}
                      </td>
                    </tr>
                    </tbody>
                    <tfoot
                        v-if="dataResults.length > moreDataTablePerPage"
                        class="bg-gray-50">
                    <tr>
                      <th
                          scope="col"
                          colspan="2"
                          class="py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        <div class="w-full">
                          <pagination
                              :pagination="paginationData"
                              class="justify-between"
                              @set-page="changePage" />
                        </div>
                      </th>
                    </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { QueryDates }                                         from "@/views/Forms/Components/Show/formShowInfoDateSelection";
import { computed, ref, useAttrs, useSlots }                  from "vue";
import { QLPaginatorFragmentFragment, useFormStatFieldQuery } from "@/graphql/queries/ql/composables";
import { toRefs }                                             from "@vueuse/core";
import PieChart                                               from "@/components/layout/Charts/PieChart.vue";
import { slice }                                              from "lodash";
import SvgIcon                                                from "@/components/SvgIcon.vue";
import Pagination                                             from "@/components/layout/Pagination.vue";
import Empty                                                  from "@/components/layout/Empty.vue";

interface Props {
  formId: string;
  question: string;
  dates: QueryDates;
  title: string;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);
const iProps = toRefs(props);

const moreDataTablePerPage = 10;

const dataQuery = useFormStatFieldQuery(() => {
  return {
    question_id: iProps.question.value,
    input:       {
      starts_at: iProps.dates.value.starts_at,
      ends_at:   iProps.dates.value.ends_at,
      id:        iProps.formId.value
    }
  };
});

const dataResults = computed(() => {
  return dataQuery.result.value?.form_stat_field ?? [];
});
const cleanResults = computed(() => {
  if (!dataResults.value) {
    return [];
  }
  return dataResults.value.map((item) => {
    const answ = JSON.parse(item.answer);
    return {
      answer: (answ === true || answ === false) ? (answ ? "Yes" : "No") : answ,
      cc:     item.cc
    };
  });
});
const {loading} = dataQuery;

const hasMoreToSee = computed(() => {
  return !!dataResults.value && dataResults.value.length > 5;
});

const moreOpen = ref(false);

const currentPage = ref(1);

const changePage = (page) => {
  currentPage.value = page;
};

const paginationData = computed((): QLPaginatorFragmentFragment => {
  let lastItem;
  const firstItem = ((currentPage.value - 1) * moreDataTablePerPage + 1);
  if (firstItem + moreDataTablePerPage > dataResults.value.length) {
    lastItem = dataResults.value.length;
  } else {
    lastItem = firstItem + moreDataTablePerPage;
  }
  return {
    count:        dataResults.value.length,
    currentPage:  currentPage.value,
    firstItem:    firstItem,
    hasMorePages: lastItem < dataResults.value.length,
    lastItem:     lastItem,
    lastPage:     Math.ceil(dataResults.value.length / moreDataTablePerPage),
    perPage:      moreDataTablePerPage,
    total:        dataResults.value.length
  };
});

const orderedItems = computed(() => {
  if (!dataResults.value) {
    return [];
  }
  return cleanResults.value.map((i) => i).sort((a, b) => (a.cc < b.cc) ? 1 : -1);
});

const moreDataInTable = computed(() => {
  return slice(orderedItems.value,
               paginationData.value.firstItem - 1,
               paginationData.value.firstItem - 1 + moreDataTablePerPage);
});

</script>
