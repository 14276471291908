<template>
  <div class="relative">
    <h3 class="font-semibold text-gray-600 mb-2">
      LTV Over Time
    </h3>
    <div
        v-if="loading"
        class="text-gray-500 flex items-center justify-center h-56"
        style="min-height: 6rem">
      <div class="h-8 w-8 ">
        <loader />
      </div>
    </div>
    <div
        v-else
        class="h-full relative">
      <time-chart
          :items="ltvItems"
          class="h-56"
          item-name="date"
          item-value="ltv" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import Loader                                                 from "@/components/layout/Loader.vue";
import TimeChart                                              from "@/components/layout/Charts/TimeChart.vue";
import { computed, toRef }                                    from "vue";
import { QLClient, QLSale, QLSortOrder, useClientSalesQuery } from "@/graphql/queries/ql/composables";

interface Props {
  client: QLClient;
}

const props = withDefaults(defineProps<Props>(), {});

const client = toRef(props, "client");

const salesQ = useClientSalesQuery(() => ({
  client_id: client.value.id,
  page:      1,
  first:     1000,
  orderBy:   [
    {
      column: "sold_at",
      order:  QLSortOrder.ASC
    }
  ]
}));

const sales = computed(() => salesQ.result.value?.sales.data ?? []);

const {loading} = salesQ;

const ltvItems = computed(() => {
  if (!sales.value) {
    return [];
  }
  let total = 0;
  return sales.value.map((sale: QLSale) => {
    total += sale.price;
    return {date: sale.sold_at, ltv: total};
  }).sort((a, b) => a.date < b.date ? -1 : 1);

});

</script>
