<template>
  <div>
    <div
        v-if="loading"
        class="h-40 flex items-center justify-center">
      <loader class="w-8 h-8 text-gray-300" />
    </div>

    <div v-if="!loading">
      <div
          v-if="invoices.length === 0"
          class="flex items-center justify-center py-8 px-4 text-gray-600">
        You don't have any invoices yet.
      </div>

      <div v-if="simplePaginated.paginatedItems.length > 0">
        <span class="font-semibold pb-2">Invoices</span>

        <div class="divide-y">
          <div
              v-for="invoice in simplePaginated.paginatedItems"
              :key="invoice.id"
              class="py-4">
            <div class="flex items-center">
              <div class="grow ">
                <timestamp-to-date
                    :timestamp="invoice.date"
                    has-date />
              </div>
              <div class="">
                <span class="px-2 py-1 text-sm font-semibold bg-gray-100 rounded-full">
                  <currency :amount="invoice.total > 0 ? invoice.total / 100 : 0" />
                </span>
              </div>
              <div class="pl-6">
                <a
                    :href="'https://loopspark.com/billing/invoices/' + invoice.id + '/download'"
                    class="text-blue-800 hover:text-blue-500"
                    target="_blank">
                  <svg-icon
                      name="download"
                      class="w-4 h-4 " />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="flex justify-between">
            <div>
              <button
                  v-if="simplePaginated.page > 1"
                  class="btn btn-white"
                  @click="simplePaginated.setPage(simplePaginated.page - 1)">
                <svg-icon
                    name="left"
                    class="w-4 h-4" />
              </button>
            </div>
            <div>
              <button
                  v-if="simplePaginated.hasMorePages"
                  class="btn btn-white"
                  @click="simplePaginated.setPage(simplePaginated.page + 1)">
                <svg-icon
                    name="right"
                    class="w-4 h-4" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, reactive } from "vue";
import TimestampToDate        from "@/components/layout/TimestampToDate.vue";
import Loader                 from "@/components/layout/Loader.vue";
import SvgIcon                from "@/components/SvgIcon.vue";
import { useInvoicesQuery }   from "@/graphql/queries/ql/composables";
import fakeSimplePagination   from "@/composables/fakeSimplePagination";
import Currency               from "@/components/layout/Currency.vue";

const {result, loading} = useInvoicesQuery();
const invoices = computed(() => result.value?.invoices ?? []);

const sortedInvoices = computed(() => {
  return [...invoices.value].sort((a, b) => {
    return a.date < b.date ? 1 : -1;
  });
});
const simplePaginated = reactive(fakeSimplePagination(sortedInvoices, 5));

</script>
