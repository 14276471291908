<template>
  <div class="mt-8 container mx-auto grow">
    <div class="md:flex md:space-x-4 lg:space-x-10">
      <div class="md:w-1/3 mb-8 md:mb-0">
        <event-information
            v-if="singleClass"
            :single-event="singleClass" />
        <div
            v-if="!singleClass"
            class="animate-loading overflow-hidden">
          <div class="mb-5">
            <loading-skeleton
                :max="30"
                class="text-2xl"
                class-type="inline"
                random-text />
            <loading-skeleton
                class="text-lg"
                class-type="inline"
                random-text />
          </div>
          <div class="text-sm space-y-3">
            <loading-skeleton
                class-type="inline"
                random-text />
            <loading-skeleton
                class-type="inline"
                random-text />
            <loading-skeleton
                class-type="inline"
                random-text />
          </div>
        </div>
      </div>
      <event-attendance
          v-if="singleClass"
          :single-event="singleClass"
          class="grow" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import EventInformation        from "@/components/Events/Show/EventInformation.vue";
import LoadingSkeleton         from "@/components/layout/LoadingSkeleton.vue";
import EventAttendance         from "@/components/Events/Show/EventAttendance.vue";
import { computed }            from "vue";
import { useRouter }           from "vue-router";
import { useSingleClassQuery } from "@/graphql/queries/ql/composables";
import { echoListener }        from "@/composables/echoListener";
import { EventTypes }          from "@/classes/notifications/EventTypes";

interface Props {
  id: string;
}

const props = withDefaults(defineProps<Props>(), {});
const router = useRouter();

const classQ = useSingleClassQuery(() => ({ id: props.id }), { fetchPolicy: "cache-and-network" });

const { loading } = classQ;
const singleClass = computed(() => classQ.result.value?.single_class ?? null);

echoListener([ EventTypes.VisitWasUpdated ], (ev) => {
  if (ev.data.visit_id == props.id) {
    classQ.refetch();
  }
}, 5000);

</script>
