<template>
  <page-title title="Catalog" section="Automations" sub-text="The Automation Catalog contains templates professionally prepared by LoopSpark to help you create the most commonly used automations.">
  </page-title>
  <div class="container mx-auto grow">

    <div
        v-if="loading"
        class="w-full h-48 flex items-center justify-center">
      <loader class="w-10 h-10 text-gray-400" />
    </div>

    <div
        v-if="!loading"
        class="md:flex">
      <div class="w-lg shrink-0 max-w-lg mb-6 md:mb-0">
        <div>
          <span class="uppercase text-sm text-gray-600 mb-1">Categories</span>

          <ul class="mt-2 bg-white rounded-lg shadow-xs overflow-hidden">
            <li
                v-for="category in categories"
                :key="category.name"
                class="menu-item"
                :class="{'selected': currentCategory === category}"
                @click="currentCategory = category">
              <div class="flex justify-between items-center">
                <div class="grow">
                  {{ category.name }}
                </div>
                <div class="ml-2">
                  <div class="rounded-full py-1 justify-center items-center bg-gray-50">
                  <span class=" block px-4 text-xs font-semibold">
                    {{ category.count }}
                  </span>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <div
              v-if="canCreateCatalog"
              class="mt-4">
            <router-link
                :to="{name: routeList.automations.catalog.create}"
                class="text-link">
              Create new catalog
            </router-link>
          </div>
        </div>
      </div>

      <div class="grow md:ml-8">
        <ul class="divide-y bg-white shadow rounded-lg hover:overflow-hidden">
          <li
              v-for="catalog in catalogsToDisplay"
              :key="catalog.id">
            <router-link
                tag="a"
                :to="{name: routeList.automations.catalog.show, params:{id: catalog.id}}"
                class="block px-4 py-6 hover:bg-blue-100 cursor-pointer">
              <div class="flex justify-between items-center">
                <div class="grow">
                  <h3 class="font-semibold pb-3 text-blue-900">
                    {{ catalog.name }}
                  </h3>
                  <p class="text-sm leading-relaxed">
                    {{ catalog.description }}
                  </p>
                </div>
                <div>
                  <svg-icon name="right" class="h-6 w-6 text-blue-900" />
                </div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<style scoped>
.menu-item {
  @apply px-4 py-3 text-gray-800 ;
}

.menu-item.selected {
  @apply bg-gray-100 font-medium cursor-default ;
}


.menu-item:hover {
  @apply bg-blue-100 cursor-pointer text-blue-800;
}

</style>

<script lang="ts" setup>
import SvgIcon                  from "@/components/SvgIcon.vue";
import Loader                   from "@/components/layout/Loader.vue";
import { computed, ref }        from "vue";
import { useCatalogItemsQuery } from "@/graphql/queries/ql/composables";
import meQuery                  from "@/composables/queries/meQuery";
import routeList                from "@/router/Routes";
import userMeDecorator          from "@/composables/decorators/userMeDecorator";
import PageTitle                from "@/components/navbar/PageTitle.vue";

const currentCategoryInt = ref(null);

const catalogQ = useCatalogItemsQuery();
const {loading} = catalogQ;

const catalog_items = computed(() => catalogQ.result.value?.catalog_items ?? []);

const {me} = meQuery();

const categories = computed(() => {
  // noinspection CommaExpressionJS
  const counted = catalog_items.value.map((i) => i.category)
                               .sort((a, b) => a > b ? 1 : -1)
                               .reduce((a, c) => (a[c] = (a[c] || 0) + 1, a), {});

  return Object.keys(counted).map((k) => {
    return {
      name:  k,
      count: counted[k]
    };
  });
});

const catalogsToDisplay = computed(() => {
  if (!currentCategory.value) {
    return [];
  }
  return catalog_items.value.filter((item) => item.category === currentCategory.value.name);
});

const currentCategory = computed({
                                   get: () => {
                                     if (currentCategoryInt.value !== null) {
                                       return currentCategoryInt.value;
                                     }
                                     return categories.value.length > 0 ? categories.value[0] : null;
                                   },
                                   set: (val) => currentCategoryInt.value = val
                                 });

const canCreateCatalog = computed(() => {
  if (!me.value) {
    return false;
  }
  return userMeDecorator(me).methods.hasPermission("manage catalog");
});

</script>
