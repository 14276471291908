<template>
  <div
      ref="form"
      class="h-full flex flex-col">
    <text-input
        v-model="formDataModel.subject"
        :disabled="sending"
        :error-message="formErrors.get('subject')"
        class="pb-4"
        label="Subject"
        placeholder="To-Do subject"
        required />

    <div class="grow pb-4 flex flex-col">
      <label class="form-label pb-1" :class="{'has-error': formErrors.has('message')}">
        Content
      </label>
      <div class="grow relative">
        <rich-editor
            v-model="formDataModel.message"
            :disabled="sending"
            class="h-full"
            placeholder="To-Do content" />
      </div>
      <span class="form-error-message" v-if="formErrors.has('message')">{{ formErrors.get("message") }}</span>
    </div>
    <div class="flex mb-4 items-center">
      <label
          class="form-label mr-4 w-1/5"
          for="priority">
        Priority
      </label>
      <select
          id="priority"
          v-model="formDataModel.priority_type"
          class="form-select block w-full form-input-default"
          :class="{'has-error': formErrors.has('priority_type')}">
        <option :value="priorities.NONE">
          None
        </option>
        <option :value="priorities.LOW">
          Low
        </option>
        <option :value="priorities.MEDIUM">
          Medium
        </option>
        <option :value="priorities.URGENT">
          Urgent
        </option>
      </select>
    </div>

    <div class="flex pb-4 items-center">
      <label
          class="form-label mr-4 w-1/5"
          for="overdue">
        Overdue
      </label>
      <div class="flex w-full">
        <select
            id="overdue"
            v-model="overdue"
            class="form-select form-input-default block w-full"
            name="overdue"
            :class="{'has-error': formErrors.has('overdue_at')}">
          <option :value="false">
            No overdue
          </option>
          <option :value="true">
            Overdue at
          </option>
        </select>
        <date-time-picker
            v-if="overdue"
            v-model="formDataModel.overdue_at"
            position="is-top-right"
            class="form-input-default" />
      </div>
    </div>

    <div class="flex pb-4 items-center">
      <label
          class="form-label mr-4 w-1/5"
          for="schedule">
        Scheduled
      </label>
      <div class="flex w-full">
        <select
            id="schedule"
            v-model="schedule"
            class="form-select form-input-default block w-full"
            name="schedule"
            :class="{'has-error': formErrors.has('postponed_to')}">
          <option :value="false">
            Right now
          </option>
          <option :value="true">
            Scheduled at
          </option>
        </select>
        <date-time-picker
            v-if="schedule"
            v-model="formDataModel.postponed_to"
            :min-date="minDate"
            position="is-top-right"
            class="form-input-default" />
      </div>
    </div>
    <div class="flex pb-4 items-center">
      <label
          class="form-label mr-4 w-1/5"
          for="assigned">
        Assigned to person
      </label>
      <div class="flex w-full">
        <select
            id="assigned"
            v-model="formDataModel.assigned_to_id"
            class="form-select form-input-default block w-full"
            :class="{'has-error': formErrors.has('assigned_to_id')}">
          <option :value="null">
            Everyone
          </option>
          <option
              v-for="user in users"
              :key="user.id"
              :value="parseInt(user.id)">
            {{ user.name }}
          </option>
        </select>
      </div>
    </div>
    <div
        v-if="groups"
        class="flex pb-4 items-center">
      <label
          class="form-label mr-4 w-1/5"
          for="assigned">
        Assigned to group
      </label>
      <div class="flex w-full">
        <select
            id="group"
            v-model="formDataModel.team_group_id"
            class="form-select form-input-default block w-full"
            :class="{'has-error': formErrors.has('team_group_id')}">
          <option :value="null">
            Everyone
          </option>
          <option
              v-for="teamGroup in groups"
              :key="teamGroup.id"
              :value="teamGroup.id">
            {{ teamGroup.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>

import DateTimePicker                                                          from "@/components/layout/Forms/DateTimePicker.vue";
import RichEditor                                                              from "@/components/layout/RichEditor.vue";
import TextInput                                                               from "@/components/layout/Forms/TextInput.vue";
import SparkFormErrors, { SparkErrors }                                        from "@/classes/utils/SparkFormErrors";
import { DateTime }                                                            from "luxon";
import TodoCreateEditInterface                                                 from "@/components/client/Todos/TodoCreateEditInterface";
import { computed, ref, toRef }                                                from "vue";
import { QLTodoPriority, useCurrentTeamGroupsQuery, useCurrentTeamUsersQuery } from "@/graphql/queries/ql/composables";

interface Props {
  sending: boolean;
  errors: SparkErrors;
  formData: TodoCreateEditInterface;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits([ "update:formData" ]);

const form = ref(null);

const groupsQ = useCurrentTeamGroupsQuery();
const groups = computed(() => groupsQ.result.value?.current_team?.groups ?? []);

const usersQ = useCurrentTeamUsersQuery();
const users = computed(() => usersQ.result.value?.current_team?.users ?? []);

const formData = toRef(props, "formData");

const formDataModel = computed({
                                 get: () => props.formData,
                                 set: (val) => {
                                   emit("update:formData", val);
                                 }
                               });

const formErrors = computed(() => {
  return new SparkFormErrors(props.errors);
});

const minDate = computed(() => {
  return DateTime.local().toSeconds();
});

const overdue = computed({
                           get: () => !!formData.value.overdue_at,
                           set: (val) => {
                             formDataModel.value.overdue_at = val ? minDate.value : null;
                           }
                         });

const schedule = computed({
                            get: () => !!formData.value.postponed_to,
                            set: (val) => {
                              formDataModel.value.postponed_to = val ? minDate.value : null;
                            }
                          });
const priorities = QLTodoPriority;

</script>
