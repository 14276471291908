<template>
  <div class="w-full inline-flex items-center">
    <loader
        v-if="loading"
        class="w-4 h-4 text-gray-600"
    />

    <select
        v-if="!loading"
        v-model="selectedValue"
        class="w-full h-full form-select form-input-default"
    >
      <optgroup
          v-for="mbo_index in mboIndexes"
          :key="mbo_index.id"
          :label="mbo_index.name"
      >
        <option
            v-for="indexValue in mbo_index.values"
            :key="indexValue.id"
            :value="{id: indexValue.external_id, name: indexValue.name}"
        >
          {{ indexValue.name }}
        </option>
      </optgroup>
    </select>
  </div>
</template>
<script lang="ts" setup>

import MboClientIndexOperator     from "hm-filters/operators/MboClientIndexOperator";
import { useMboIndexesQuery }     from "@/graphql/queries/ql/composables";
import Loader                     from "@/components/layout/Loader.vue";
import { computed, toRef, watch } from "vue";

interface Props {
  operator: MboClientIndexOperator;
  modelValue: any;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits(["update:modelValue", "is-valid"]);

const {loading, result} = useMboIndexesQuery();

const mboIndexes = computed(() => result.value?.mbo_indexes ?? []);

const selectedValue = computed({
                                 get: () => {
                                   if (props.modelValue === null) {
                                     return [];
                                   }
                                   return props.modelValue;
                                 },
                                 set: (newVal) => emit("update:modelValue",
                                                       newVal !== null ? {id: newVal.id, name: newVal.name} : null)
                               });

const validCalculation = () => {
  const valid = !(props.modelValue === null || props.modelValue.length == 0);
  emit("is-valid", valid);
  return valid;
};

const isValid = computed(() => {
  return validCalculation();
});
const modelValue = toRef(props, "modelValue");
watch(modelValue, validCalculation);

</script>
