<template>
  <div class="grid lg:grid-cols-2 gap-3 lg:gap-6 grid-flow-row-dense">
    <div class="flex flex-col rounded-lg shadow overflow-hidden">
      <div class="flex items-center grow bg-white p-3 py-5">
        <div class="p-3 mr-4 text-loopspark-500 bg-loopspark-100 rounded-full dark:text-loopspark-100 dark:bg-loopspark-500">
          <svg-icon
              name="calendar-small"
              class="w-5 h-5" />
        </div>
        <div>
          <p class="text-sm tracking-wide text-gray-600 dark:text-gray-400">
            Last form completed
          </p>
          <div
              v-if="loading"
              class="animate-loading">
            <div class="loading-bg w-12 h-4 mt-2" />
          </div>
          <p
              v-if="!loading"
              class="text-lg font-semibold  text-gray-900 dark:text-gray-200">
              <span v-if="lastForm"><timestamp-to-date
                  :timestamp="lastForm.created_at"
                  natural-date /></span>
            <span v-else>-</span>
          </p>
        </div>
      </div>
      <div
          v-if="!loading && lastForm"
          class="bg-gray-100 px-3 py-2 text-sm">
        <router-link
            :to="{name: routeList.client.forms.show, params:{id: client.id, formInstanceId: lastForm.id}}"
            class="text-loopspark-500 font-medium">
          View form details
        </router-link>
      </div>
    </div>
    <div class="flex flex-col rounded-lg shadow overflow-hidden">
      <div class="flex items-center grow bg-white p-3 py-5">
        <div class="p-3 mr-4 text-loopspark-500 bg-loopspark-100 rounded-full dark:text-loopspark-100 dark:bg-loopspark-500">
          <svg-icon
              name="signature-duotone"
              class="w-5 h-5" />
        </div>
        <div>
          <p class="text-sm tracking-wide text-gray-600 dark:text-gray-400">
            Total forms completed
          </p>
          <div
              v-if="loading"
              class="animate-loading">
            <div class="loading-bg w-12 h-4 mt-2" />
          </div>
          <p
              v-if="!loading"
              class="text-lg font-semibold  text-gray-900 dark:text-gray-200">
            <span v-if="lastFormPagination">{{ lastFormPagination.total }}</span>
            <span v-else>-</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import SvgIcon from "@/components/SvgIcon.vue";

import { QLClient, QLSortOrder, useFormInstancesClientDetailQuery } from "@/graphql/queries/ql/composables";
import { computed, toRef }                                          from "vue";
import TimestampToDate                                              from "@/components/layout/TimestampToDate.vue";
import { useRouter }                                                from "vue-router";
import routesList                                                   from "@/composables/routesList";
import { echoListener }                                             from "@/composables/echoListener";
import { EventTypes }                                               from "@/classes/notifications/EventTypes";

interface Props {
  client: QLClient;
}

const router = useRouter();
const { routeList } = routesList();

const props = withDefaults(defineProps<Props>(), {});

const client = toRef(props, "client");

const lastFormQuery = useFormInstancesClientDetailQuery(() => {
  return {
    client_id: client.value.id,
    orderBy:   [
      {
        column: "created_at",
        order:  QLSortOrder.DESC
      }
    ],
    page:      0,
    first:     1
  };
});

const { loading } = lastFormQuery;

const lastFormData = computed(() => lastFormQuery.result.value?.form_instances_client_detail?.data ?? null);

const lastForm = computed(() => {
  if (!lastFormData.value) {
    return null;
  }
  return lastFormData.value[0];
});

const lastFormPagination = computed(() => lastFormQuery.result.value?.form_instances_client_detail?.paginatorInfo ?? null);

echoListener([ EventTypes.FormInstanceWasCreated ], (ev) => {
  if (ev.data.client_id == client.value.id) {
    lastFormQuery.refetch();
  }
}, 5000);

</script>
