<template>
  <dashboard-stat-item
      title="To-Dos assigned to me"
      icon="todo"
      :route-name="routes.todos"
      link-name="View all To-Dos"
      :loading="loading"
      :value="todoCount" />
</template>

<script lang="ts" setup>
import { computed }              from "vue";
import { useMyTodosCountsQuery } from "@/graphql/queries/ql/composables";
import routesList                from "@/composables/routesList";
import DashboardStatItem         from "@/components/dashboard/DashboardStatItem.vue";
import { echoListener }          from "@/composables/echoListener";
import { EventTypes }            from "@/classes/notifications/EventTypes";

const {result, loading, refetch} = useMyTodosCountsQuery();

const todoCount = computed(() => result.value?.my_todos_counts.paginatorInfo.total ?? 0);
const routes = routesList().routeList;

echoListener([EventTypes.TodoNew, EventTypes.TodoUpdated], (ev) => {
  refetch();
}, 5000);

</script>
