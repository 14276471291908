<template>
  <div class="block relative max-w-full">
    <router-link
        :to="{name: routeList.client.discussions.index, params:{id: client.id}}"
        class="text-blue-600 hover:text-blue-800 flex items-center mb-4">
      <div class="block">
        <svg-icon
            class="w-4 h-4"
            name="left" />
      </div>
      <span class="text-sm">All discussions with {{ client.first_name }}</span>
    </router-link>
    <div class="max-w-full">
      <div class="lg:flex lg:flex-row mb-4 lg:shadow-lg lg:max-h-screen-80 ">
        <div class="shadow lg:shadow-none lg:order-2 lg:w-3/12 lg:shrink-0 p-4 bg-gray-50 mb-4 lg:mb-0 rounded-lg lg:rounded-none lg:border-l border-gray-300 lg:rounded-r divide-y">
          <div class="pb-8">
            <span class="text-sm uppercase text-gray-600 leading-relaxed block tracking-wider mb-2">Information</span>
            <div v-if="discussion">
              <div class="text-sm  leading-relaxed">
                This <span
                  class="font-medium"
                  v-text="discussion.email ? 'email': 'text'" />
                discussion
                was initiated on
                <timestamp-to-date
                    :timestamp="discussion.created_at"
                    class="font-medium"
                    has-date
                    has-time />
                by
                <div
                    v-if="!discussion.automation_id"
                    class="inline font-medium">
                  {{ initiatior }}
                </div>
                <div
                    v-if="discussion.automation_id"
                    class="inline">
                  the automation
                  <router-link
                      :to="{name: routeList.automations.show.index, params:{id: discussion.automation_id}}"
                      class="font-medium text-blue-600 hover:text-blue-800">
                    {{
                      discussion.automation.name
                    }}
                  </router-link>
                </div>
              </div>
            </div>
            <div
                v-if="!discussion"
                class="animate-loading">
              <div class="text-sm space-y-1">
                <loading-skeleton
                    class-type="w-full"
                    text="-" />
                <loading-skeleton
                    class-type="w-full"
                    text="-" />
                <loading-skeleton
                    class-type="w-2/3"
                    text="-" />
              </div>
            </div>
          </div>
          <div class="pt-8">
            <span class="text-sm uppercase text-gray-600 leading-relaxed block tracking-wider mb-2">Status</span>

            <div
                v-if="!discussion"
                class="animate-loading">
              <p class="font-bold text-base">
                <loading-skeleton text="Open" />
              </p>
              <p class="text-xs pt-2 pb-1">
                <loading-skeleton
                    class-type="block"
                    text="-" />
              </p>
              <p class="text-xs pb-1">
                <loading-skeleton
                    class-type="block"
                    text="-" />
              </p>
              <p class="text-xs">
                <loading-skeleton
                    class-type="block w-2/3"
                    text="-" />
              </p>

              <div class="pt-8 flex justify-center">
                <div class="loading-bg h-16 w-full block rounded" />
              </div>
            </div>
            <div v-if="discussion">
              <div v-if="!discussion.closed">
                <p class="font-bold text-lg">
                  Open
                </p>
                <p class="text-xs pt-2  leading-relaxed">
                  Open discussion are ones that we expect a response, or have not been
                  resolved yet.
                </p>

                <div class="pt-8 flex justify-center">
                  <v-button
                      :disabled="sendingData.closing"
                      :loading="sendingData.closing"
                      class="btn btn-white"
                      @click="closeDiscussion">
                    Close
                    this
                    discussion
                  </v-button>
                </div>
              </div>

              <div v-if="discussion.closed">
                <p class="font-bold text-lg">
                  Closed
                </p>
                <p class="text-xs pt-2  leading-relaxed">
                  Closed discussion are ones that have reached an ending point. If a
                  customer responds to a closed discussion, you will be notified and
                  the status will automatically change to open.
                </p>

                <div class="pt-8 flex justify-center">
                  <v-button
                      :disabled="sendingData.closing"
                      :loading="sendingData.closing"
                      class="btn btn-white"
                      @click="openDiscussion">
                    Re-open
                    this
                    discussion
                  </v-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="lg:order-1 block w-full lg:w-9/12">
          <div class="shadow-lg rounded-lg lg:shadow-none lg:rounded-none flex flex-col h-full w-full">
            <div
                class="bg-white py-4 px-2 rounded-t lg:rounded-none lg:rounded-tl scrolling-touch overflow-y-auto w-full grow  flex flex-col justify-between">
              <div v-if="discussion">
                <single-message
                    v-for="message in messages"

                    :key="message.id"
                    :client="client"
                    :discussion="discussion"
                    :message="message"
                    class="mb-8" />
              </div>
              <div
                  v-if="!discussion"
                  class="animate-loading">
                <div class="mb-8 w-full flex">
                  <div class="px-2 flex w-full md:w-5/6">
                    <div class="shrink">
                      <div class="w-8 h-8 block loading-bg rounded-full" />
                    </div>
                    <div class="px-2 w-full ">
                      <div class="block rounded-lg rounded-tl-none p-2 w-full h-20 loading-bg" />
                      <div class="flex space-x-2 mt-2">
                        <div class="block h-5 w-32 loading-bg" />
                        <div class="block h-5 w-16 loading-bg" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-8 w-full flex flex-row-reverse">
                  <div class="px-2 flex md:w-5/6 flex-row-reverse">
                    <div class="shrink">
                      <div class="w-8 h-8 block loading-bg rounded-full" />
                    </div>
                    <div class="px-2 w-full ">
                      <div class="block rounded-lg rounded-tr-none p-2 w-full h-20 loading-bg" />
                      <div class="flex flex-row-reverse space-x-2 space-x-reverse mt-2">
                        <div class="block h-5 w-32 loading-bg" />
                        <div class="block h-5 w-16 loading-bg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div ref="bottom"/>
              <div class="w-full flex justify-center items-center" v-if="discussion && !canSendSms">
                <div class="rounded bg-red-50 px-4 py-2 text-red-900 text-sm max-w-lg">{{client.first_name}} has not opted-in to receive text messages. To contact them via text messages, they need to first send START to your studio's number<span v-if="currentTeamNumber">, {{phone(currentTeamNumber)}}</span></div>
              </div>


            </div>

            <div
                v-if="discussion"
                class="bg-gray-200 px-2 py-2 rounded-b lg:rounded-none lg:rounded-bl border-t border-gray-300 grow-0">

              <form
                  class="flex"
                  @submit="sendMessage">
                <div class="grow">
                  <div class="relative grow">
                    <text-area-input
                        v-model="messageModel"
                        :disabled="!discussion || sendingData.sending || !canSendSms"
                        :error-message="sendingData.sendError ? 'Please try again': (discussion.sms && smsData.errorMessage) ? smsData.errorMessage : null"
                        :max-length="discussion.sms ? smsData.maxLength: null"
                        :min-length="3"
                        :rows="5"
                        :replace-utf="discussion.sms"
                        required>
                      <template #internal>
                        <div v-if="discussion.sms"
                             class="flex justify-between items-baseline ">
                          <div v-if="messageModel.length === 0"></div>
                          <s-m-s-units-counter
                              :msg="messageModel"
                              v-if="messageModel.length >0"
                              :units="3"
                              class="pl-3 text-gray-500 text-sm" />
                          <div class="grow">
                            <quick-responses-dropdown
                                :client="client"
                                @selected="messageModel = $event" />
                          </div>
                        </div>
                      </template>
                    </text-area-input>
                  </div>
                </div>
                <div class="shrink pl-2">
                  <v-button
                      :disabled="!discussion || !canSendSms"
                      :loading="sendingData.sending"
                      class="btn btn-green"
                      type="submit">
                    Reply
                  </v-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import SvgIcon                                                                                                                 from "@/components/SvgIcon.vue";
import TimestampToDate                                                                                                         from "@/components/layout/TimestampToDate.vue";
import TextAreaInput                                                                                                           from "@/components/layout/Forms/TextAreaInput.vue";
import SingleMessage                                                                                                           from "@/components/discussions/show/SingleMessage.vue";
import VButton                                                                                                                 from "@/components/layout/VButton.vue";
import LoadingSkeleton                                                                                                         from "@/components/layout/LoadingSkeleton.vue";
import QuickResponsesDropdown                                                                                                  from "@/components/QuickResponses/QuickResponsesDropdown.vue";
import { computed, ComputedRef, nextTick, reactive, ref, toRef, watch }                                                        from "vue";
import { QLClient, QLDiscussion, QLMessage, useDiscussionAddMessageMutation, useDiscussionQuery, useDiscussionUpdateMutation } from "@/graphql/queries/ql/composables";
import routesList                                                                                                              from "@/composables/routesList";
import $toast                                                                                                                  from "@/composables/toast";
import { echoListener }                                                                                                        from "@/composables/echoListener";
import { EventTypes }                                                                                                          from "@/classes/notifications/EventTypes";
import { smsLengthInit }                                                                                                       from "@/composables/smsLength";
import { smsCleanedContent }                                                                                                   from "@/composables/smsCleanedContent";
import { toastIfRateLimitWasHit }                                                                                              from "@/composables/rateLimits";
import SMSUnitsCounter                                                                                                         from "@/components/layout/SMSUnitsCounter.vue";
import currentTeamQuery                                                                                                        from "@/composables/queries/currentTeamQuery";
import phone                                                                                                                   from "@/classes/vue-filters/PhoneFilter";

interface Props {
  client: QLClient;
  discussionId: string;
}

const {routeList} = routesList();

const props = withDefaults(defineProps<Props>(), {});

const client = toRef(props, "client");
const bottom = ref<HTMLElement>(null);

const discussionQ = useDiscussionQuery(() => ({id: props.discussionId}));

const discussion: ComputedRef<QLDiscussion> = computed(() => discussionQ.result.value?.discussion ?? null);

echoListener([EventTypes.DiscussionUpdated, EventTypes.MessageUpdated, EventTypes.MessageNew], (ev) => {
  if (ev.data.discussion_id == (discussion.value?.id ?? null)) {
    discussionQ.refetch();
  }
}, 5000);

const firstLoading = computed(() => {
  return discussionQ.loading.value && discussion.value.messages.length === 0;
});

const currentTeam = currentTeamQuery();

const currentTeamNumber = computed(() => {
  return currentTeam.currentTeam.value?.phone_number ?? null;
});

const sendingData = reactive({
                               closing:    false,
                               sending:    false,
                               sendError:  false,
                               newMessage: ""
                             });

const messageModel = smsCleanedContent().computed(toRef(sendingData, "newMessage"));

const initiatior = computed(() => {
  if (!discussion.value) {
    return "";
  }
  if (discussion.value.messages[0].from_client) {
    return "the client";
  }
  if (discussion.value.messages[0].user?.id) {
    return discussion.value.messages[0].user.name;
  }

  return "";
});

const messages = computed(() => {
  if (!discussion.value) {
    return [];
  }
  return discussion.value.messages.map(i => i).sort((a: QLMessage, b: QLMessage) => a.created_at - b.created_at);
});

watch(messages, () => {
  // this is done to scroll to the end of the discussion list
  nextTick().then(() => {
    bottom.value.scrollIntoView(false);
  });
});

const changeDiscussionStatusMutation = useDiscussionUpdateMutation({});
changeDiscussionStatusMutation.onError(() => {
  $toast({
           type:    "error",
           message: "There was an issue while updating this discussion. Please try again"
         });
});

changeDiscussionStatusMutation.onDone(() => {
  sendingData.closing = false;
});

const closeDiscussion = () => {
  sendingData.closing = true;
  changeDiscussionStatusMutation.mutate({
                                          id:     props.discussionId,
                                          closed: true
                                        });
};
const openDiscussion = () => {
  sendingData.closing = true;
  changeDiscussionStatusMutation.mutate({
                                          id:     props.discussionId,
                                          closed: false
                                        });
};

const sendMessageMutation = useDiscussionAddMessageMutation({});

sendMessageMutation.onError((err) => {
  toastIfRateLimitWasHit(err);

  sendingData.sendError = true;
  sendingData.sending = false;
});

sendMessageMutation.onDone(() => {
  sendingData.newMessage = "";
  sendingData.sending = false;
  discussionQ.restart();
});

const sendMessage = (e) => {
  e.preventDefault();
  sendingData.sendError = false;
  sendingData.sending = true;
  sendMessageMutation.mutate({
                               id:      props.discussionId,
                               message: sendingData.newMessage
                             });
};

const smsData = smsLengthInit(toRef(sendingData, "newMessage"));

const canSendSms = computed(() => {
  return discussion.value && !(discussion.value.sms && client.value && !client.value.can_contact_by_sms);
});

</script>
