<template>
  <page-title
      section="Settings"
      title="Billing" />
  <div class="container mx-auto grow space-y-16">
    <div class="split-view-left">
      <div class="pb-4 md:pb-0 md:w-1/3">
        <div class="text-lg font-medium text-gray-900">
          Your subscription
        </div>
        <div class="mt-1 text-gray-600 text-sm">
          <div class="space-y-6">
            <p>
              Your subscription will bill the payment method on hand until cancelled. You may cancel your account at any
              time to prevent future billings using the “cancel subscription” button located on this page.
            </p>
            <p>
              LoopSpark uses one of the industries most trusted payment processors, Stripe. LoopSpark passed your
              billing information to Stripe as it is entered on our site, and we never store or have access to your
              payment method. Stripe will handle all storage of financial information, updates of payment methods,
              refunds, cancellations, and invoicing.
            </p>
            <p>
              <a
                  class="text-link"
                  href="https://stripe.com/about"
                  target="_blank">Learn more about Stripe</a>
            </p>
          </div>
        </div>
      </div>

      <div class="flex-1 block ">
        <loader
            v-if="loadingSub"
            class="h-8 w-8 text-gray-500" />

        <div
            v-if="!loadingSub && mySubscription"
            class="shadow-md rounded-lg bg-white p-4">
          <studio-setting-billing-subscription
              :current-team="currentTeam"
              :my-subscription="mySubscription"
              @updated="refreshTheSubscription" />
        </div>

        <div
            v-if="!loadingSub && !mySubscription"
            class="shadow-md rounded-lg bg-white p-4">
          <studio-setting-billing-start
              :current-team="currentTeam"
              @updated="refreshTheSubscription" />
        </div>
      </div>
    </div>

    <div v-if="shouldShowBillingUpdate && formAddonsEnabled && currentTeam && currentTeam.approved"
         class="split-view-left">
      <div class="pb-4 md:pb-0 md:w-1/3">
        <div class="text-lg font-medium text-gray-900">
          Your Addons
        </div>
        <div class="mt-1 text-gray-600 text-sm">
          <div class="space-y-6">
            <p>
              In addition to your LoopSpark subscription, you can select optional add-on services.Add-on services can
              be activated or cancelled at anytime without cancelling your LoopSpark subscription.
            </p>
          </div>
        </div>
      </div>

      <div class="flex-1 block ">
        <loader
            v-if="loadingSub"
            class="h-8 w-8 text-gray-500" />

        <div
            v-if="!loadingSub && mySubscription"
            class="">
          <studio-setting-billing-addons
              :current-team="currentTeam"
              :my-subscription="mySubscription"
              @updated="updateAll" />
        </div>
      </div>
    </div>

    <div
        v-if="shouldShowBillingUpdate"
        class="split-view-left">
      <div class="pb-4 md:pb-0 md:w-1/3">
        <div class="text-lg font-medium text-gray-900">
          Payment Method
        </div>
        <div class="mt-1 text-gray-600 text-sm">
          <p>
            You can update your payment method at anytime. If your payment method expires or fails to charge, you will
            receive a notification directly from Stipe. Failure to update declined payment methods within 1 week of
            initial charge attempt may result in automatic subscription termination.
          </p>
        </div>
      </div>
      <div class="flex-1 block ">
        <loader
            v-if="loadingSub"
            class="h-8 w-8 text-gray-500" />

        <div
            v-if="!loadingSub"
            class="shadow-md rounded-lg bg-white p-4">
          <studio-setting-billing-update-billing
              :current-team="currentTeam"
              @updated="refreshTheTeam" />
        </div>
      </div>
    </div>

    <div
        v-if="!currentTeamLoading && !currentTeam.is_free"
        class="split-view-left">
      <div class="pb-4 md:pb-0 md:w-1/3">
        <div class="text-lg font-medium text-gray-900">
          Invoices
        </div>
        <div class="mt-1 text-gray-600 text-sm">
          <p>
            View and download your monthly invoices in PDF format.
          </p>
        </div>
      </div>
      <div class="flex-1 block ">
        <div class="shadow-md rounded-lg bg-white p-4">
          <studio-setting-billing-invoices />
        </div>
      </div>
    </div>

    <div
        v-if="isCancellable"
        class="split-view-left">
      <div class="pb-4 md:pb-0 md:w-1/3">
        <div class="text-lg font-medium text-gray-900">
          Cancel Subscription
        </div>
        <div class="mt-1 text-gray-600 text-sm">
          <p>
            Cancelling your LoopSpark account will end your subscription at the end of your current billing period. Your
            account will remain active for the duration of the current month you've paid for.
          </p>
          <div class="mt-4 text-red-900">
            <p>
              <strong>Warning:</strong>
            </p>
            <ul class="pt-2 space-y-2 list-inside list-disc">
              <li>Automations will stop</li>
              <li>Anyone connected to your LoopSpark account will lose access to your studio profile</li>
              <li>Local telephone number provided by LoopSpark will be released.</li>
              <li>All segments, text and email wording, and other saved data will be deleted.</li>
              <li>LoopSpark will delete access to your booking software.</li>
              <li>LoopSpark will delete all client data.</li>
              <li>You will not longer receive any monthly bills.</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="flex-1 block">
        <studio-setting-billing-cancel-subscription
            :current-team="currentTeam"
            :my-subscription="mySubscription"
            @updated="refreshTheSubscription" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import PageTitle                              from "@/components/navbar/PageTitle.vue";
import Loader                                 from "@/components/layout/Loader.vue";
import StudioSettingBillingSubscription       from "@/views/Studio/Settings/Components/Billing/StudioSettingBillingSubscription.vue";
import StudioSettingBillingCancelSubscription from "@/views/Studio/Settings/Components/Billing/StudioSettingBillingCancelSubscription.vue";
import StudioSettingBillingUpdateBilling      from "@/views/Studio/Settings/Components/Billing/StudioSettingBillingUpdateBilling.vue";
import StudioSettingBillingStart              from "@/views/Studio/Settings/Components/Billing/StudioSettingBillingStart.vue";
import StudioSettingBillingInvoices           from "@/views/Studio/Settings/Components/Billing/StudioSettingBillingInvoices.vue";
import StudioSettingBillingAddons             from "@/views/Studio/Settings/Components/Billing/StudioSettingBillingAddons.vue";
import { computed }                           from "vue";
import currentTeamQuery                       from "@/composables/queries/currentTeamQuery";
import { useMySubscriptionQuery }             from "@/graphql/queries/ql/composables";
import { formAddonsEnabledFn }                from "@/composables/formAddonsEnabled";

const { currentTeam, loading: currentTeamLoading, refetch: refetchTeam } = currentTeamQuery();

const { result, loading: loadingSub, refetch } = useMySubscriptionQuery();
const mySubscription = computed(() => result.value?.my_subscription ?? null);

const shouldShowBillingUpdate = computed(() => {
  return !loadingSub.value && mySubscription.value != null;
});

const isCancellable = computed(() => {
  return !loadingSub.value && mySubscription.value && mySubscription.value.ends_at === null;
});
const formAddonsEnabled = formAddonsEnabledFn();

const refreshTheSubscription = () => {
  refetch();
};

const refreshTheTeam = () => {
  refetchTeam();
};

const updateAll = () => {
  refreshTheSubscription();
  refreshTheTeam();
};

</script>
