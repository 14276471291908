<template>
  <dashboard-stat-item
      title="Open discussions"
      icon="chats-circle-duotone"
      :route-name="routes.discussions"
      link-name="View all discussions"
      :loading="loading"
      :value="statusCount" />
</template>
<script lang="ts" setup>
import DashboardStatItem  from "@/components/dashboard/DashboardStatItem.vue";
import { computed }       from "vue";
import { useStatusQuery } from "@/graphql/queries/ql/composables";
import routesList         from "@/composables/routesList";

const {result, loading} = useStatusQuery();
const statusCount = computed(() => result.value?.status.discussions.open ?? 0);
const routes = routesList().routeList;

</script>
