<template>
  <div>
    <timeframe-selector
        v-model:date="dateSelected"
        position="left"
        :min-date="form.created_at"
        class="mb-4" />

    <div class="space-y-3 lg:space-y-6 ">
      <div class="grid lg:grid-cols-3 gap-3 lg:gap-6 grid-flow-row-dense">
        <div class="flex flex-col rounded-lg shadow overflow-hidden">
          <stat-box-simple
              :current="totalsCurrentPeriodResult"
              :loading="loadingStats.totals"
              :past="totalsPastPeriodResult"
              :show-past="showPastData">
            <template #icon>
              <svg-icon
                  name="signature-duotone"
                  class="w-5 h-5"
                  icon="email" />
            </template>
            <template #title>
              Completed
            </template>
          </stat-box-simple>
        </div>
        <div class="flex flex-col rounded-lg shadow overflow-hidden">
          <stat-box-simple
              :current="firstCompletedResult"
              :loading="loadingStats.first"
              :show-past="false">
            <template #icon>
              <svg-icon
                  name="clock"
                  class="w-5 h-5"
                  icon="email" />
            </template>
            <template #main>
              <timestamp-to-date
                  v-if="firstCompletedResult"
                  :timestamp="firstCompletedResult ? firstCompletedResult : null"
                  has-date />
              <span v-if="!firstCompletedResult">-</span>
            </template>
            <template #title>
              First completion
            </template>
          </stat-box-simple>
        </div>
        <div class="flex flex-col rounded-lg shadow overflow-hidden">
          <stat-box-simple
              :current="totalCompletedResult"
              :loading="loadingStats.total"
              :show-past="false">
            <template #icon>
              <svg-icon
                  name="stack-duotone"
                  class="w-5 h-5" />
            </template>
            <template #title>
              Total form completions
            </template>
          </stat-box-simple>
        </div>
      </div>
      <div class="bg-white shadow rounded-lg p-4">
        <h3 class="font-semibold text-gray-600 mb-2 pl-2">
          Form submissions by date
        </h3>
        <form-show-history-chart
            :form-id="form.id"
            :dates="infoDate.queryDatesCurrent" />
      </div>
      <div class="grid lg:grid-cols-2 gap-3 lg:gap-6 grid-flow-row-dense">
        <div
            v-for="chartableBlock in chartableBlocks"
            :key="chartableBlock.id">
          <form-show-single-question-pie-chart
              :form-id="form.id"
              :question="chartableBlock.id"
              :dates="infoDate.queryDatesCurrent"
              :title="chartableBlock.title" />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { QLFragmentFormFullFragment, useFormStatCompletionTimesQuery, useFormStatFirstCompletionQuery } from "@/graphql/queries/ql/composables";
import { computed, toRef }                                                                              from "vue";
import TimeframeSelector                                                                                from "@/components/layout/TimeframeSelector.vue";
import formShowInfoDateSelection                                                                        from "@/views/Forms/Components/Show/formShowInfoDateSelection";
import StatBoxSimple                                                                                    from "@/components/layout/StatBoxSimple.vue";
import SvgIcon                                                                                          from "@/components/SvgIcon.vue";
import { DateTime }                                                                                     from "luxon";
// Removed useResult import
import TimestampToDate                                                                                  from "@/components/layout/TimestampToDate.vue";
import FormBlockDecorator                                                                               from "@/classes/entities/decorators/FormBlockDecorator";
import FormShowSingleQuestionPieChart                                                                   from "@/views/Forms/Components/Show/FormShowSingleQuestionPieChart.vue";
import FormShowHistoryChart                                                                             from "@/views/Forms/Components/Show/FormShowHistoryChart.vue";

interface Props {
  form: QLFragmentFormFullFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits([]);

const form = toRef(props, "form");

const infoDate = formShowInfoDateSelection(form);

const {showPastData, dateSelected} = infoDate;

const totalsCurrentPeriod = useFormStatCompletionTimesQuery(infoDate.queryVariablesCurrent,
                                                            infoDate.queryOptionsCurrent);
const totalsPastPeriod = useFormStatCompletionTimesQuery(infoDate.queryVariablesPast, infoDate.queryOptionsPast);

const firstCompleted = useFormStatFirstCompletionQuery(infoDate.queryVariablesCurrent,
                                                       infoDate.queryOptionsCurrent);
const totalCompleted = useFormStatCompletionTimesQuery(() => ({
                                                         input: {
                                                           id:        form.value.id,
                                                           starts_at: 1577854800, // 2020-1-1
                                                           ends_at:   DateTime.local().endOf("year").toSeconds()
                                                         }
                                                       }),
                                                       infoDate.queryOptionsCurrent);

const loadingStats = computed(() => {
  return {
    totals: totalsCurrentPeriod.loading.value || totalsPastPeriod.loading.value,
    first:  firstCompleted.loading.value,
    total:  totalCompleted.loading.value
  };
});

const totalsCurrentPeriodResult = computed(() =>
                                               totalsCurrentPeriod.result.value?.form_stat_completion_times?.completed ?? 0
);
const totalsPastPeriodResult = computed(() =>
                                            totalsPastPeriod.result.value?.form_stat_completion_times?.completed ?? 0
);
const firstCompletedResult = computed(() =>
                                          firstCompleted.result.value?.form_stat_first_completion?.first ?? 0
);
const totalCompletedResult = computed(() =>
                                          totalCompleted.result.value?.form_stat_completion_times?.completed ?? 0
);

const chartableBlocks = computed(() => {
  if (!form.value) {
    return [];
  }
  return form.value.content.map((block) => {
    if ((new FormBlockDecorator(block)).isChartable) {
      return block;
    }
    return false;
  }).filter((block) => block !== false);
});

</script>
