import { computed, reactive, ref, Ref, watch } from "vue";
import currentTeamQuery                        from "@/composables/queries/currentTeamQuery";
import { useEmailTemplateQuery }               from "@/graphql/queries/ql/composables";
import SparkFormErrors                         from "@/classes/utils/SparkFormErrors";
import EmailTemplateContent                    from "@/classes/entities/EmailTemplateContent";

export function loadEmailTemplate(id: Ref<string>) {
	const currentTeamQ = currentTeamQuery();
	const { currentTeam } = currentTeamQ;

	const emailTemplateQ = useEmailTemplateQuery({ id: id.value });
	const { loading } = emailTemplateQ;
	const emailTemplate = computed(() => emailTemplateQ.result.value?.email_template ?? null);

	const sending = ref(false);
	const errorsData = ref({});

	const errors = computed(() => {
		return new SparkFormErrors(errorsData.value);
	});

	const emailAdditionalData = reactive({
		                                     name:         "",
		                                     subject:      "",
		                                     internalData: {
			                                     html: null,
			                                     json: null
		                                     }
	                                     });

	watch(emailTemplate, () => {
		if (currentTeam.value !== null && emailTemplate.value !== null) {
			prepareInternalEmail();
		}
	});
	watch(currentTeam, () => {
		if (currentTeam.value !== null && emailTemplate.value !== null) {
			prepareInternalEmail();
		}
	});

	const previewSubject = computed(() => {
		if (!emailAdditionalData.subject || emailAdditionalData.subject.length === 0) {
			return "[PREVIEW] - ";
		}
		return "[PREVIEW] - " + emailAdditionalData.subject;
	});

	const prepareInternalEmail = () => {
		let j = typeof emailTemplate.value.email?.json === "string" ? emailTemplate.value.email.json : JSON.stringify(
			emailTemplate.value.email?.json);
		j = EmailTemplateContent.replaceColorInString(j, currentTeamQ.currentTeam.value.color);
		emailAdditionalData.internalData = {
			html: emailTemplate.value.email?.html,
			json: JSON.parse(j)
		};
		emailAdditionalData.name = emailTemplate.value.name ?? "";
		emailAdditionalData.subject = emailTemplate.value.subject ?? "";
	};

	return {
		currentTeamQ,
		currentTeam,
		emailTemplateQ,
		loading,
		emailTemplate,
		sending,
		errorsData,
		errors,
		emailAdditionalData,
		previewSubject
	};
}
