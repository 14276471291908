<template>
  <div>
    <p>
      You are not currently subscribed to LoopSpark. To get started, select a plan below, add your payment method and
      click "subscribe"
    </p>

    <loader
        v-if="loadingPlans"
        class="w-8 h-8 text-gray-500" />

    <div
        v-if="!loadingPlans"
        class="grid grid-cols-1 gap-4 lg:grid-cols-2 py-8">
      <div
          v-for="plan in regularPlans"
          :key="plan.id"
          class="radio-button cursor-pointer"
          role="radio"
          :class="{'selected': selectedPlan && selectedPlan.id === plan.id}"
          @click="selectedPlan = plan">
        <div class="text-center">
          <strong class="text-lg font-semibold">{{ plan.name }}</strong>
          <p class="py-2">
            <strong class="font-semibold">
              <currency :amount="plan.price" />
            </strong> / {{
              plan.interval === "MONTHLY"
              ? "month"
              : "year"
                      }} per location
          </p>
          <p>
            <strong class="font-semibold">{{
                plan.interval === "MONTHLY"
                ? "Monthly"
                : "Yearly"
                                          }}</strong> committment
          </p>
        </div>
      </div>
    </div>

    <div class="space-y-4">
      <div>
        <text-input
            v-model="cardHolderName"
            autocomplete="name"
            label="Cardholder's Name"
            name="name"
            required
            type="text" />
      </div>
      <div>
        <label
            class="form-label"
            :class="{'has-error': errors.has('card')}">Credit Card</label>
        <div id="card-element" />
        <span
            v-if="errors.has('card')"
            class="form-error-message">{{ errors.get("card") }}</span>
      </div>
      <div class="pt-4">
        <v-button
            :disabled="sending || !selectedPlan"
            :loading="sending"
            class="btn btn-green"
            @click="subscribeToPlan">
          Subscribe!
        </v-button>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, onMounted, ref }                                                   from "vue";
import VButton                                                                        from "@/components/layout/VButton.vue";
import TextInput                                                                      from "@/components/layout/Forms/TextInput.vue";
import Loader                                                                         from "@/components/layout/Loader.vue";
import SparkFormErrors, { SparkErrors }                                               from "@/classes/utils/SparkFormErrors";
import getStripeCardToken                                                             from "@/composables/billing/stripeCardToken";
import { QLFragmentCurrentTeamFragment, usePlansQuery, useStartSubscriptionMutation } from "@/graphql/queries/ql/composables";
import $toast                                                                         from "@/composables/toast";
import Currency                                                                       from "@/components/layout/Currency.vue";
import fillValidationErrorsOrToast                                                    from "@/composables/queries/mutations/fillValidationErrorsOrToast";
import { AllEventTypes }                                                              from "@/classes/notifications/EventTypes";
import { EventBus }                                                                   from "@/classes/notifications/EventBus";

interface Props {
  currentTeam: QLFragmentCurrentTeamFragment;
}

const props = withDefaults(defineProps<Props>(), {});
const emit = defineEmits(["updated"]);

const sending = ref(false);
const errorsData = ref<SparkErrors>({});
const errors = computed(() => {
  return new SparkFormErrors(errorsData.value);
});

const {
  cardHolderName,
  getPaymentMethod,
  onMounted: stripeOnMounted
} = getStripeCardToken("#card-element", errorsData);

onMounted(() => {
  stripeOnMounted();
});

const {result, loading: loadingPlans} = usePlansQuery();
const plans = computed(() => result.value?.plans ?? []);
const selectedPlan = ref(null);

const regularPlans = computed(() => {
  if (!plans.value) {
    return [];
  }
  return plans.value.filter((p) => !p.addon);
});

const mutation = useStartSubscriptionMutation({});

mutation.onError((err) => {
  fillValidationErrorsOrToast(err, errorsData, "There was a problem with your payment. Please try again");
  sending.value = false;
});

mutation.onDone(() => {
  EventBus.getInstance().emit(AllEventTypes.RefreshTeam, {data: {}});

  $toast({
           type:         "success",
           message:      "Payment method updated",
           linkRouteObj: null,
           linkText:     null
         });
  sending.value = false;
  emit("updated");
});

const subscribeToPlan = async () => {
  errorsData.value = {};
  sending.value = true;

  try {
    const paymentMethod = await getPaymentMethod();

    await mutation.mutate({
                            payment_method: paymentMethod,
                            plan:           selectedPlan.value.id
                          });

  } catch (e) {
    $toast({
             type:         "error",
             message:      "There was a problem with your payment. Please try again",
             linkRouteObj: null,
             linkText:     null
           });
    sending.value = false;
  }

};

</script>
