<template>
  <modal
      v-model:open="openModel"
      :has-close-button="true"
      full-heigth
      full-width>
    <div class="w-full grow h-full">
      <div class="text-xl text-gray-900 font-medium">
        Import rules from another segment
      </div>
      <div class="text-sm text-gray-600">
        Copy rules from a saved audience segment. Saved segments include audiences used
        in one of your automations and segments saved on your client list page
      </div>

      <div class="lg:flex mt-8 lg:space-x-4">
        <div class="lg:w-1/2">
          <label class="form-label mb-1">Select a segment</label>

          <smart-select
              v-model="selectedSegment"
              :items="segments"
              label="name">
            <template #default="slotProps">
              <div>
                <span class="block">{{ slotProps.item.name }}</span>
                <span class="text-xs text-gray-500 group-hover:text-white"><span v-text="slotProps.item.is_communication? 'From automation': 'Custom segment'" /></span>
              </div>
            </template>
          </smart-select>

          <div
              v-if="selectedSegment"
              class="mt-8 flex items-center justify-center">
            <v-button
                class="btn btn-green max-w-full"
                @click="importSegment">
              <span>Import segment:</span>
              <span class="block truncate">{{ selectedSegment.name }}!</span>
            </v-button>
          </div>
        </div>
        <div class="lg:w-1/2 mt-4 lg:mt-0">
          <div
              v-if="selectedSegment"
              class="p-4 bg-gray-50 border-gray-100 rounded-lg">
            <div class="text-gray-800 font-medium mb-2">
              Segment: {{ selectedSegment.name }}
            </div>

            <segment-view-edit
                v-model="selectedSegment"
                :editable="false" />
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script lang="ts" setup>

import Modal                                               from "@/components/layout/Modal.vue";
import SmartSelect                                         from "@/components/layout/Forms/SmartSelect.vue";
import VButton                                             from "@/components/layout/VButton.vue";
import SegmentViewEdit                                     from "@/components/Segment/SegmentViewEdit.vue";
import { computed, ref }                                   from "vue";
import { QLFragmentFullSegmentFragment, useSegmentsQuery } from "@/graphql/queries/ql/composables";
import { deepCopy }                                        from "@/classes/helpers/DeepCopy";

interface Props {
  open: boolean;
}

const props = defineProps<Props>();

const emit = defineEmits(["opened", "closed", "importSegment", "update:open"]);

const {result, loading} = useSegmentsQuery({archived: false}, {fetchPolicy: "cache-first"});

const segments = computed(() => result.value?.segments ?? []);

const selectedSegment = ref<QLFragmentFullSegmentFragment | null>(null);

const openModel = computed({
                             get: () => props.open,
                             set: (val) => emit("update:open", val)
                           });

const open = () => {
  openModel.value = true;
  emit("opened");
};

const close = () => {
  openModel.value = false;
  emit("closed");
};

const importSegment = () => {
  const seg = deepCopy(selectedSegment.value);
  openModel.value = false;
  selectedSegment.value = null;
  emit("importSegment", seg);
};

defineExpose({
               open,
               close
             });

</script>
