<template>
  <div v-if="loading || recaps.length > 0">
    <h2 class="text-xl mb-3">
      Form Completion Overview
    </h2>

    <div
        v-if="loading"
        class="py-16 w-full flex items-center justify-center animate-loading">
      <loader class="w-8 h-8" />
    </div>

    <div
        v-if="!loading && recaps.length > 0"
        class="flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 md:px-6 lg:px-8 align-middle">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full">
              <thead class="bg-white">
              <tr>
                <th
                    scope="col"
                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-full">
                  Form name
                </th>
                <th
                    scope="col"
                    class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                  Last Completion
                </th>
                <th
                    scope="col"
                    class="px-3 py-3.5 text-sm font-semibold text-gray-900 text-right">
                  Completions
                </th>
                <th
                    scope="col"
                    class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span class="sr-only">More</span>
                </th>
              </tr>
              </thead>
              <tbody class="bg-white">
              <template
                  v-for="(recap, cat) in recapsByCategory"
                  :key="cat">
                <tr class="border-t border-gray-200">
                  <th
                      colspan="5"
                      scope="colgroup"
                      class="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6">
                    {{ cat !== fakeUncategorized ? cat : "Uncategorized" }}
                  </th>
                </tr>
                <client-form-recap-table-line
                    v-for="(recapItem, recapIdx) in recap"
                    :key="recapItem.id"
                    :class="[recapIdx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t']"
                    :client="client"
                    :line="recapItem" />
              </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { QLClient, QLFragmentFormWithCountFragment, useFormInstancesRecapQuery } from "@/graphql/queries/ql/composables";
import { computed, toRef }                                                       from "vue";
import { groupBy }                                                               from "lodash";
import { deepCopy }                                                              from "@/classes/helpers/DeepCopy";
import ClientFormRecapTableLine                                                  from "@/views/ClientShow/Components/Forms/ClientFormRecapTableLine.vue";
import Loader                                                                    from "@/components/layout/Loader.vue";
import { echoListener }                                                          from "@/composables/echoListener";
import { EventTypes }                                                            from "@/classes/notifications/EventTypes";

interface Props {
  client: QLClient;
}

const props = withDefaults(defineProps<Props>(), {});

const client = toRef(props, "client");

const emit = defineEmits([]);

const recapQuery = useFormInstancesRecapQuery(() => {
  return {
    client_id: client.value.id
  };
});

echoListener([ EventTypes.FormInstanceWasCreated ], (ev) => {
  if (ev.data.client_id == client.value.id) {
    recapQuery.refetch();
  }
}, 5000);

const fakeUncategorized = "ZZZZZZZZZZZZZZZZ";

recapQuery.onError((err) => console.log(err));

const { loading } = recapQuery;

const recaps = computed(() => recapQuery.result.value?.form_instances_recap ?? []);

const recapsByCategory = computed((): Record<string, QLFragmentFormWithCountFragment[]> => {
  if (!recaps.value) {
    return {};
  }
  return groupBy(
      recaps.value
            .map((i) => {
              const copied = deepCopy(i);
              if (!copied.category) {
                copied.category = fakeUncategorized;
              }
              return copied;
            })
            .sort((a, b) => a.category.toLocaleLowerCase() > b.category.toLocaleLowerCase() ? 1 : -1),
      "category");
});

</script>
