<template>
  <div class="space-y-16">
    <div class="space-y-16">
      <div class="split-view-left">
        <div class="pb-4 md:pb-0 md:w-1/3">
          <div class="text-lg font-medium text-gray-900">
            General information
          </div>
          <div class="mt-1 text-gray-600 text-sm space-y-5">
            <p>General information about the form to keep your form list organized.</p>

            <p>
              <strong>Tip:</strong> Use a name that is easy to understand the purpose of the form (eg - liability waiver
                                    v1.1 or first class follow up survey).
            </p>

            <p>
              Categorize the form to group your form list by similar objective (example: Liability Waivers,
              Satisfaction Surveys, New Client Intake Questionnaires ).
            </p>
          </div>
        </div>

        <div class="block overflow-hidden shadow-md rounded-lg flex-1 bg-white">
          <div class="p-4 space-y-6">
            <text-input
                v-model="formName"
                :error-message="formErrors.get('name')"
                data-name
                label="Name"
                placeholder="Example: Liability Waiver - Version 1"
                required>
              <div class="leading-tight form-small-info text-gray-600">
                Use a name that is easy to understand the purpose of the form and version of your form (Example:
                Liability Waiver Version 1)
              </div>
            </text-input>
            <div>
              <label
                  class="form-label"
                  :class="{'has-error': formErrors.has('category')}">
                Category
              </label>
              <smart-select
                  v-model="formModel.category"
                  :error-message="formErrors.get('category')"
                  :items="formCategories"
                  allow-new
                  class="mt-1"
                  placeholder="Example: Liability Waivers" />
              <div class="leading-tight form-small-info text-gray-600">
                Select an existing category or start typing to create a new category.
              </div>
            </div>

            <text-area-input
                v-model="formModel.description"
                :error-message="formErrors.get('description')"
                :rows="5"
                label="Description"
                placeholder="Example: Liability waiver used for new and existing clients. Waiver requires a client signature, will be saved as a PDF and forwarded to client's email address once submitted.">
              <div class="leading-tight form-small-info text-gray-600">
                Add a short description to help yourself (and staff) understand the purpose of this form. This
                will
                also help LoopSpark Support debug your form if you ask for help.
              </div>
            </text-area-input>
          </div>
        </div>
      </div>
    </div>
    <div
        v-if="!form.is_archived"
        class="space-y-16">
      <div class="split-view-left">
        <div class="pb-4 md:pb-0 md:w-1/3">
          <div class="text-lg font-medium text-gray-900">
            Form Settings
          </div>
          <div class="mt-1 text-gray-600 text-sm space-y-5">
            <p>
              Select if your form will be saved as a PDF, and whether or not your form will allow new
              clients (clients that do not exist in your booking software). Form settings cannot be changed after the
              first completed form is submitted.
            </p>

            <p>
              <strong>Tip:</strong> Waivers such as liability waivers should be marked "save as PDF" which will send the
                                    client a copy of their completed waiver in addition to saving a PDF copy in
                                    LoopSpark. If you'd like to also receive a copy of all completed PDFs, select "Send
                                    a copy" and add your forwarding email address.
            </p>

            <p>
              If your form allows new clients (clients that don't exist in your booking software), select "allow new
              clients." If you plan on making your form available to new leads, or having the form open on a tablet for
              anyone to complete, select "allow new clients."
            </p>
          </div>
        </div>

        <div class="block flex-1 ">
          <div class="p-4 py-6 space-y-6 bg-white shadow-md rounded-lg">
            <text-input
                v-model="formModel.title"
                :error-message="formErrors.get('title')"
                required
                type="text"
                label="Form Title (visible to clients)"
                placeholder="">
              <div class="leading-tight form-small-info text-gray-600">
                This title will be displayed to the customer.
              </div>
            </text-input>
            <div v-if="editable">
              <div class="flex items-center justify-between space-x-4">
                  <span class="flex-grow flex flex-col">
                    <span class=" form-label">Save a PDF</span>
                    <span class="text-sm text-gray-500">Use for liability waivers. If enabled, the client will receive an email copy of the completed form, and a PDF copy will be stored in the client's LoopSpark profile.</span>
                  </span>
                <toggle-checkbox v-model:model-value="isLegalModel" />
              </div>
            </div>
            <div v-if="formModel.is_legal">
              <div class="flex items-center justify-between space-x-4">
                  <span class="flex-grow flex flex-col">
                    <span class=" form-label">Send a copy </span>
                    <span class="text-sm text-gray-500">In addition to emailing the completed PDF to your customer, you can choose an email address where the completed form will be emailed. We recommend the studio owner's email address.</span>
                  </span>
                <toggle-checkbox v-model:model-value="sendCopyByEmail" />
              </div>
            </div>
            <text-input
                v-if="formModel.is_legal && sendCopyByEmail"
                v-model="formModel.email_cc"
                :error-message="formErrors.get('email_cc')"
                required
                autocomplete="email"
                type="email"
                label="Send a copy of the PDF to this email"
                placeholder="">
              <div class="leading-tight form-small-info text-gray-600">
                PDF forms will automatically be sent to the customer. In addition by selecting "send a copy" you
                can choose an email address where the completed form will be emailed. We recommend the studio owner's
                email address.
              </div>
            </text-input>
            <div v-if="formModel.is_legal && isMindbody">
              <div class="flex items-center justify-between space-x-4">
                  <span class="flex-grow flex flex-col">
                    <span class=" form-label">Upload completed PDF to Mindbody</span>
                    <span
                        v-if="!(formModel.allow_leads && !formModel.mbo_upload_lead)"
                        class="text-sm text-gray-500">You can decide to upload a copy of the completed PDF to the existing client's "Documents" section on MINDBODY.</span>
                    <span
                        v-if="formModel.allow_leads && !formModel.mbo_upload_lead"
                        class="text-sm text-gray-500">You can decide to upload a copy of the completed PDF to the existing client's "Documents" section on MINDBODY. Warning: If <span
                        class="font-medium whitespace-nowrap">Create a profile in Mindbody</span> is NOT selected, new leads will NOT upload a completed PDF to Mindbody. Their PDF will be saved in LoopSpark.</span>
                  </span>
                <toggle-checkbox v-model:model-value="formModel.send_to_mbo" />
              </div>
            </div>
            <div v-if="isMindbody">
              <div class="flex items-center justify-between space-x-4">
                  <span class="flex-grow flex flex-col">
                    <span class=" form-label">Update profile in Mindbody</span>
                    <span class="text-sm text-gray-500">Select this option if you'd like a user's form responses to update client records in Mindbody. If selected, you will have the option to map form fields back to specific Mindbody client profile fields.</span>
                  </span>
                <toggle-checkbox v-model:model-value="formModel.mbo_update_client" />
              </div>
            </div>
            <div>
              <div class="flex items-center justify-between space-x-4">
                  <span class="flex-grow flex flex-col">
                    <span class=" form-label">Allow new clients</span>
                    <span class="text-sm text-gray-500">If your form will be available to contacts that don't exist in your booking software, select "allow new clients." New clients will be added to LoopSpark as "leads" upon submitting a completed form.</span>
                  </span>
                <toggle-checkbox v-model:model-value="allowsLeads" />
              </div>
            </div>
            <div v-if="formModel.allow_leads && isMindbody">
              <div class="flex items-center justify-between space-x-4">
                  <span class="flex-grow flex flex-col">
                    <span class=" form-label">Create a profile in Mindbody</span>
                    <span class="text-sm text-gray-500">If your form allows new clients, click this toggle if you'd like a client profile to be created in Mindbody following form submission.</span>
                  </span>
                <toggle-checkbox v-model:model-value="formModel.mbo_upload_lead" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
        v-if="!form.is_archived"
        class="space-y-16">
      <div class="split-view-left">
        <div class="pb-4 md:pb-0 md:w-1/3">
          <div class="text-lg font-medium text-gray-900">
            Thank you settings
          </div>
          <div class="mt-1 text-gray-600 text-sm space-y-5">
            <p>
              Write a "thank you" message that will appear when a customer completes the form, or choose a custom URL
              where the customer will be redirected.
            </p>
            <p>
<strong>Tip:</strong> If you're creating a liability waiver, you might want to redirect the customer to
                                     your class schedule, making it easier for them to book their first class.
</p>
          </div>
        </div>

        <div class="block overflow-hidden shadow-md rounded-lg flex-1 bg-white">
          <div class="p-4">
            <div
                class="border-2 rounded-lg py-4 mb-4 px-4 hover:border-blue-200 hover:bg-blue-100 hover:text-blue-800"
                role="button"
                :class="{'selected-box': textThankYouSelected}"
                @click="textThankYouSelected = true">
              <span class="text-base font-medium block mb-2">Custom Thank You Message</span>
              <p class="text-xs">
                Design the text a customer will see when they successfully complete your form. This can be a simple
                confirmation or "thank you" message.
              </p>
              <div
                  v-if="textThankYouSelected"
                  class="mt-4 bg-white">
                <rich-editor
                    v-model="formModel.thank_you_message"
                    class="h-64" />
              </div>
            </div>
            <div
                class="border-2 rounded-lg py-4 px-4 hover:border-blue-200 hover:bg-blue-100 hover:text-blue-800"
                role="button"
                :class="{'selected-box': !textThankYouSelected}"
                @click="textThankYouSelected = false">
              <span class="text-base font-medium block mb-2">Redirect to URL</span>
              <p class="text-xs">
                When customer completes your waiver, redirect them to a custom URL of your choice.
              </p>

              <div
                  v-if="textThankYouSelected === false"
                  class="mt-4">
                <text-input
                    v-model="formModel.thank_you_url"
                    :error-message="formErrors.get('thank_you_url')"
                    label="URL"
                    type="url"
                    placeholder="https://www.yourwebsite.com/thank-you"
                    required>
                  <div class="leading-tight form-small-info text-gray-600">
                    Customer will be automatically redirected from the waiver completion to the URL of your choice. We
                    recommend redirecting to your business webpage, page to schedule classes or purchase packages.
                  </div>
                </text-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
        v-if="form.is_archived"
        class="space-y-16">
      <div class="split-view-left">
        <div class="pb-4 md:pb-0 md:w-1/3">
          <div class="text-lg font-medium text-gray-900">
            Archive settings
          </div>
          <div class="mt-1 text-gray-600 text-sm space-y-5">
            <p>
              Forms that are archived will no longer be accessible to clients. Create a custom message that will be
              displayed when a client views an archived form (helpful if you have a new active version of the form
              available, you can let the client know there’s a new form version and link them to the active form);
              otherwise, you can select “Missing Page” which will display a simple “404 - Page Not Found” message.
            </p>
          </div>
        </div>

        <div class="block overflow-hidden shadow-md rounded-lg flex-1 bg-white">
          <div class="p-4">
            <div
                v-if="formErrors.get('form.archive_404') || formErrors.get('form.archive_text')"
                class="form-error-message mb-4">
              You need to select one
              option.
            </div>

            <div
                :class="{'selected-box': !textArchiveTextSelected}"
                class="border-2 rounded-lg py-4 px-4 mb-4 hover:border-blue-200 hover:bg-blue-100 hover:text-blue-800"
                role="button"
                @click="textArchiveTextSelected = false">
              <span class="text-base font-medium block mb-2">Missing page</span>
              <p class="text-xs">
                Client will receive a "404 - Page Not Found” error message when accessing an archived form. We recommend
                setting up a custom message for better customer experience.
              </p>
            </div>
            <div
                :class="{'selected-box': textArchiveTextSelected}"
                class="border-2 rounded-lg py-4 px-4 hover:border-blue-200 hover:bg-blue-100 hover:text-blue-800"
                role="button"
                @click="textArchiveTextSelected = true">
              <span class="text-base font-medium block mb-2">Custom Message</span>
              <p class="text-xs">
                Create a custom message that will be displayed when a client views an archived form (helpful if you have
                a new active version of the form available, you can let the client know there’s a new form version and
                link them to the active form).
              </p>
              <div
                  v-if="textArchiveTextSelected"
                  class="mt-4 bg-white">
                <rich-editor
                    v-model="formModel.archive_text"
                    class="h-64" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
        class="split-view-left">
      <div class="pb-4 md:pb-0 md:w-1/3 shrink-0">
        <div
            v-if="editable"
            class="shadow-md rounded-lg bg-white p-4 ">
          <label
              for=""
              class="form-label mb-4">Form Content</label>
          <form-content-editor
              v-model:content="formModel.content"
              :saving="saving"
              :use-mappings="hasMappings"
              :errors="errors" />
        </div>
        <div v-if="!editable">
          <div class="text-lg font-medium text-gray-900">
            Form content cannot be edited
          </div>
          <div class="mt-1 text-gray-600 text-sm space-y-5">
            <p>
              This form has already been completed by a customer. To preserve consistency, forms that have been
              completed by at least 1 customer cannot be edited. If you need to edit this form, please archive the
              current form and recreate a new form.
            </p>
          </div>
        </div>
      </div>
      <div class="shadow-md rounded-lg bg-white p-4 grow shrink">
        <label
            for=""
            class="form-label">Form Preview</label>
        <p
            v-if="editable"
            class="mt-1 mb-4 text-xs text-gray-500">
          Important: You can edit the content of a form, but only before the first submission is completed. After any
          client submits that form, the content can no longer be edited, only the settings can be edited.
        </p>
        <form-content-preview
            :fields="formModel.content" />
      </div>
    </div>
  </div>
</template>
<style scoped>
.selected-box {
  @apply border-green-200 bg-green-50 text-green-900;
}
</style>
<script lang="ts" setup>
import { QLForm, useFormCategoriesQuery }                         from "@/graphql/queries/ql/composables";
import TextInput                                                  from "@/components/layout/Forms/TextInput.vue";
import TextAreaInput                                              from "@/components/layout/Forms/TextAreaInput.vue";
import SmartSelect                                                from "@/components/layout/Forms/SmartSelect.vue";
import { computed, ref, toRaw, toRef, useAttrs, useSlots, watch } from "vue";
import SparkFormErrors, { SparkErrors }                           from "@/classes/utils/SparkFormErrors";
import ToggleCheckbox                                             from "@/components/layout/Forms/ToggleCheckbox.vue";
import FormContentEditor                                          from "@/components/Forms/FormContentEditor.vue";
import RichEditor                                                 from "@/components/layout/RichEditor.vue";
import FormContentPreview                                         from "@/components/Forms/FormContentPreview.vue";
import currentTeamQuery                                           from "@/composables/queries/currentTeamQuery";
import meQuery                                                    from "@/composables/queries/meQuery";

interface Props {
  form: QLForm;
  errors: SparkErrors;
  saving: boolean;
  editable: boolean;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([
                           "update:form", "update:errors"
                         ]);
const formModel = toRef(props, "form");
const saving = toRef(props, "saving");
const errors = toRef(props, "errors");
const editable = toRef(props, "editable");

const {currentTeam} = currentTeamQuery();
const {me} = meQuery();

const isMindbody = computed(() => {
  if(!currentTeam.value) return false;
  if(!me.value) return false;
  return currentTeam.value.provider_mindbody;
});

const hasMappings = computed(() => {
  return isMindbody.value && (formModel.value.mbo_update_client || formModel.value.mbo_upload_lead);
});

watch(formModel, (newD, oldD) => {
  if(!saving.value) {
    emit("update:form", toRaw(oldD));
  }
}, {deep: true});

// get categories
const {result} = useFormCategoriesQuery({fetchPolicy: "cache-and-network"});
const formCategoriesRaw = computed(() => result.value.form_categories ?? []);
const formCategories = computed(() => {
  return formCategoriesRaw.value.map((cat) => cat.name);
});

const formName = computed({
                            get: () => formModel.value.name ?? '',
                            set: (val) => {
                              const err = new SparkFormErrors(props.errors);
                              err.forget("name");

                              formModel.value.name = val;
                              emit("update:errors", err.all());
                            }
                          });
const copyByEmailInt = ref(null);
const sendCopyByEmail = computed({
                                   get: () => {
                                     if (copyByEmailInt.value === null) {
                                       copyByEmailInt.value = formModel.value.email_cc?.length > 0;
                                     }
                                     return copyByEmailInt.value;
                                   },
                                   set: (val) => {
                                     copyByEmailInt.value = val;
                                     if (copyByEmailInt.value === false) {
                                       formModel.value.email_cc = null;
                                     }
                                   }
                                 });
const textThankYouSelected = computed({
                                        get: () => {
                                          return formModel.value.thank_you_message !== null;
                                        },
                                        set: (val) => {
                                          if (!val) {
                                            formModel.value.thank_you_message = null;
                                            if(!formModel.value.thank_you_url) {
                                              formModel.value.thank_you_url = "";
                                            }
                                          } else {
                                            if(!formModel.value.thank_you_message) {
                                              formModel.value.thank_you_message = "";
                                            }
                                            formModel.value.thank_you_url = null;
                                          }
                                        }
                                      });

const textArchiveTextSelected = computed({
                                           get: () => {
                                             return formModel.value.archive_text !== null;
                                           },
                                           set: (val) => {
                                             if (!val) {
                                               formModel.value.archive_text = null;
                                               formModel.value.archive_404 = true;
                                             } else {
                                               formModel.value.archive_text = "";
                                               formModel.value.archive_404 = false;
                                             }
                                           }
                                         });

const isLegalModel = computed({
                                get: () => formModel.value.is_legal,
                                set: (val) => {
                                  formModel.value.is_legal = val;
                                  if (!formModel.value.is_legal) {
                                    formModel.value.send_to_mbo = false;
                                  }
                                }
                              });

const allowsLeads = computed({
                                get: () => formModel.value.allow_leads,
                                set: (val) => {
                                  formModel.value.allow_leads = val;
                                  if (!formModel.value.allow_leads) {
                                    formModel.value.mbo_upload_lead = false;
                                  }
                                }
                              });

const formErrors = computed(() => {
  return new SparkFormErrors(props.errors);
});

</script>
