<template>
  <div v-if="!loading && invitationsPending.length > 0">
    <div class="fixed top-0 right-0 w-full max-w-lg z-1000">
      <transition-group
          class="p-4 relative space-y-8"
          name="fade"
          tag="div">
        <pending-invitation-list-item
            v-for="invitation in invitationsPending"
            :key="invitation.id"
            :invitation="invitation" />
      </transition-group>
    </div>
  </div>
</template>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition:opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity:0;
}
</style>
<script lang="ts" setup>

import { computed }                   from "vue";
import PendingInvitationListItem      from "@/components/user/invitations/PendingInvitationListItem.vue";
import { useInvitationsPendingQuery } from "@/graphql/queries/ql/composables";

const {result, loading} = useInvitationsPendingQuery();

const invitationsPending = computed(() => result.value?.invitations_pending ?? []);

</script>
