<template>
  <div>
    <div
        v-if="loading"
        class="text-gray-500 flex items-center justify-center h-56"
        style="min-height: 6rem">
      <div class="h-8 w-8 ">
        <loader />
      </div>
    </div>
    <div class="w-full h-full relative" v-if="!loading">
      <time-chart :items="dataToShow" class="h-64" :min-value="0"></time-chart>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { QueryDates }                   from "@/views/Forms/Components/Show/formShowInfoDateSelection";
import { computed, useAttrs, useSlots } from "vue";
import { toRefs }                       from "@vueuse/core";
import { useFormStatHistoryQuery }      from "@/graphql/queries/ql/composables";
import TimeChart                        from "@/components/layout/Charts/TimeChart.vue";
import { DateTime }                     from "luxon";
import Loader                           from "@/components/layout/Loader.vue";

interface Props {
  formId: string;
  dates: QueryDates;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const iProps = toRefs(props);

const dataQuery = useFormStatHistoryQuery(() => {
  return {
    input: {
      starts_at: iProps.dates.value.starts_at,
      ends_at:   iProps.dates.value.ends_at,
      id:        iProps.formId.value
    }
  };
});

const dataResults = computed(() => dataQuery.result.value?.formStatHistory ?? []);
const {loading} = dataQuery;

const dataToShow = computed(() => {
  if (!dataResults.value) {
    return [];
  }
  // I have to add the start and end dates
  const startDate = DateTime.fromSeconds(iProps.dates.value.starts_at).startOf("hour").toSeconds();
  const endDate = DateTime.fromSeconds(iProps.dates.value.ends_at).endOf("hour").toSeconds();

  let finalArray = dataResults.value.map((i) => i);

  if (dataResults.value.find((i) => i.timestamp == startDate) === undefined) {
    // add the first one
    finalArray.unshift({
                         timestamp: startDate,
                         cc:        0
                       });
  }

  if (dataResults.value.find((i) => i.timestamp == endDate) === undefined) {
    // add the last one
    finalArray.push({
                         timestamp: endDate,
                         cc:        0
                       });
  }

  return finalArray;
});

</script>
