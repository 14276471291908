<template>
  <page-title
      title="Your Forms"
      section="Forms" />
  <div class="container mx-auto grow">
    <div>
      <div class="w-full">
        <div class="">
          <div class="mb-2 flex flex-row text-sm flex-wrap items-center ">
            <div class="flex grow">
              <div>
                <div class="text-gray-700 mr-2">
                  PDF Creation:
                  <inline-muted-select
                      v-model="pdfCreation"
                      :options="pdfOptions" />
                </div>
              </div>
              <div class="ml-4">
                <div class="text-gray-700 mr-2">
                  Include Archived:
                  <inline-muted-select
                      v-model="includeArchived"
                      :options="includeArchivedOptions" />
                </div>
              </div>
              <div class="ml-4">
                <div
                    v-if="!loading"
                    class="text-gray-700 mr-2">
                  Filter by Category:
                  <inline-muted-select
                      v-model="selectedCategory"
                      :max-width="240"
                      :options="categoryNames"
                      :with-empty="true"
                      option="id" />
                </div>
              </div>
            </div>
            <div>
              <input type="text"
                     class="form-input form-input-default"
                     placeholder="Search..."
                     v-model="filterText">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-8">
      <div
          v-if="loading"
          class="w-full h-48 flex items-center justify-center">
        <loader class="w-12 h-12 text-gray-500" />
      </div>
      <div v-if="!loading">
        <div
            v-if="forms && forms.length > 0"
            class="divide-y divide-blue-100">
          <form-index-category
              v-for="(categoryAndForms,index) in formsGrouped"
              :key="index"
              :force-open="formsGrouped.length === 1"
              :include-inactive-count="true"
              :forms="categoryAndForms"
              class="py-4" />
        </div>

        <div v-if="!shownForms.length">
          <div class="w-full flex items-center justify-center">
            <div class="w-full md:w-1/2 bg-gray-100 border rounded-md h-32 flex items-center justify-center">
              <div class="px-4 py-16 text-center">
                <div class="text-xl text-bold text-gray-600">
                  Nothing to show!
                </div>
                <p class="text-gray-600">
                  There aren't any forms with the current filters
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script lang="ts" setup>
import PageTitle                                           from "@/components/navbar/PageTitle.vue";
import { computed, ref }                                   from "vue";
import InlineMutedSelect                                   from "@/components/layout/InlineMutedSelect.vue";
import Loader                                              from "@/components/layout/Loader.vue";
import { echoListener }                                    from "@/composables/echoListener";
import { EventTypes }                                      from "@/classes/notifications/EventTypes";
import FuzzySearch                                         from "fuzzy-search";
import { QLFragmentFormSmallFragment, useFormsIndexQuery } from "@/graphql/queries/ql/composables";
import FormIndexCategory                                   from "@/views/Forms/Components/Index/FormIndexCategory.vue";

const pdfCreation = ref("All");
const pdfOptions = [
  {label: "-", value: null},
  {label: "Yes", value: "Yes"},
  {label: "No", value: "No"}
];
const includeArchived = ref(false);
const includeArchivedOptions = [
  {label: "No", value: false},
  {label: "Yes", value: true}
];
const selectedCategory = ref(null);
const filterText = ref("");

const {result, loading, refetch} = useFormsIndexQuery({});
const forms = computed(() => result.value?.forms_index ?? []);
echoListener([EventTypes.FormWasCreated, EventTypes.FormWasCreated], (ev) => {
  refetch();
}, 5000);

computed(() => {
  return [
    {id: "", value: "All"},
    ...categoryNames.value
  ];
});
const clearCategoryName = (catName: string) => {
  if (!catName || catName === "" || catName.toLowerCase() === "uncategorized") {
    return null;
  }
  return catName;
};
const categoryNames = computed(() => {
  if (!forms.value) {
    return [];
  }
  return [...new Set(forms.value.map(a => clearCategoryName(a.category)))].sort((a, b) => {
    if (a === null) {
      return 1;
    }
    if (b === null) {
      return -1;
    }
    return (a.toLowerCase() < b.toLowerCase()) ? -1 : 1;
  }).map((name) => {
    return {
      id:    name !== null ? name : "Uncategorized",
      label: name !== null ? name : "Uncategorized"
    };
  });
});

const shownForms = computed(() => {
  const searcher = new FuzzySearch(filteredForms.value, ["name"], {sort: true});
  return searcher.search(filterText.value);
});

const filteredForms = computed((): QLFragmentFormSmallFragment[] => {
  if (!forms.value) {
    return [];
  }
  return forms.value.filter((aut) => {
    if (pdfCreation.value !== "All") {
      if (aut.is_legal !== (pdfCreation.value === "Yes")) {
        return false;
      }
    }
    if (includeArchived.value === false) {
      if (aut.is_archived) {
        return false;
      }
    }
    if (selectedCategory.value) {
      if (aut.category != (selectedCategory.value === "Uncategorized" ? null : selectedCategory.value)) {
        return false;
      }
    }
    return true;
  });
});
const formsGrouped = computed(() => {
  return categoryNames.value.map((categoryName) => {
    return shownForms.value.filter((aut) => aut.category === (categoryName.id === "Uncategorized"
                                                              ? null
                                                              : categoryName.id));
  });
});

</script>
