<template>
  <div>
    <div class="grid lg:grid-cols-4 gap-3 lg:gap-6 grid-flow-row-dense">
      <div class="flex flex-col rounded-lg shadow overflow-hidden">
        <div class="flex items-center grow bg-white p-3 py-5">
          <div class="p-3 mr-4 text-loopspark-500 bg-loopspark-100 rounded-full dark:text-loopspark-100 dark:bg-loopspark-500">
            <svg-icon
                name="calendar-small"
                class="w-5 h-5"
                icon="calendar" />
          </div>
          <div>
            <p class="text-sm tracking-wide text-gray-600 dark:text-gray-400">
              Last contact
            </p>
            <div
                v-if="loadingLastEngagement"
                class="animate-loading">
              <div class="loading-bg w-12 h-4 mt-2" />
            </div>
            <p
                v-if="!loadingLastEngagement"
                class="text-lg font-semibold  text-gray-900 dark:text-gray-200">
              <span v-if="lastEngagement"><timestamp-to-date
                  :timestamp="lastEngagement.created_at"
                  natural-date /></span>
              <span v-else>-</span>
            </p>
          </div>
        </div>
        <div
            v-if="!loadingLastEngagement && lastEngagement"
            class="bg-gray-100 px-3 py-2 text-sm">
          <router-link
              :to="{name: routeList.client.discussions.show, params:{id: client.id, discussionId: lastEngagement.id}}"
              class="text-loopspark-500 font-medium">
            View discussion
          </router-link>
        </div>
      </div>

      <div class="rounded-lg shadow bg-white lg:row-span-2">
        <client-engagement-medium-pie-chart
            :engagement-stats="engagementStats"
            :loading="loadingStats" />
      </div>
      <div class="rounded-lg shadow bg-white lg:col-span-2 lg:row-span-2">
        <div class="px-4 pt-4">
          <h3 class="font-semibold text-gray-600 mb-2">
            Email deliverability
          </h3>
        </div>
        <div
            v-if="loadingStats"
            class="animate-loading h-64 flex justify-center items-center">
          <div class="w-16 h-16 text-gray-400">
            <loader />
          </div>
        </div>
        <client-engagement-flow-chart
            v-if="!loadingStats"
            :engagement-stats="engagementStats"
            class="h-64" />
      </div>

      <div class="flex flex-col rounded-lg shadow overflow-hidden">
        <div class="flex items-center grow bg-white p-3 py-5">
          <div class="p-3 mr-4 text-loopspark-500 bg-loopspark-100 rounded-full dark:text-loopspark-100 dark:bg-loopspark-500">
            <svg-icon
                name="email"
                class="w-5 h-5"
                icon="email" />
          </div>
          <div>
            <p class="text-sm tracking-wide text-gray-600 dark:text-gray-400">
              Engagements
            </p>
            <p
                v-if="!loadingStats && engagementStats"
                class="text-lg font-semibold  text-gray-900 dark:text-gray-200">
              {{ totalEngagements }}
            </p>
            <div
                v-if="loadingStats"
                class="animate-loading">
              <div class="loading-bg w-12 h-4 mt-2" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="rounded-lg shadow bg-white mt-3 lg:mt-6 hidden md:block">
      <div class="px-4 pt-4">
        <h3 class="font-semibold text-gray-600 mb-2">
          Timeline
        </h3>
      </div>
      <div
          v-if="loadingTimeline"
          class="animate-loading flex justify-center items-center"
          style="height: 500px;">
        <div class="w-16 h-16 text-gray-400">
          <loader />
        </div>
      </div>
      <client-engagement-timeline
          v-if="!loadingTimeline"
          :engagements="timeline" />
    </div>

    <div class="mt-12">
      <client-engagement-discussions-list :client="client" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import SvgIcon                                                                                                                from "@/components/SvgIcon.vue";
import ClientEngagementFlowChart                                                                                              from "@/components/client/Engagement/ClientEngagementFlowChart.vue";
import ClientEngagementMediumPieChart                                                                                         from "@/components/client/Engagement/ClientEngagementMediumPieChart.vue";
import Loader                                                                                                                 from "@/components/layout/Loader.vue";
import TimestampToDate                                                                                                        from "@/components/layout/TimestampToDate.vue";
import ClientEngagementTimeline                                                                                               from "@/components/client/Engagement/ClientEngagementTimeline.vue";
import ClientEngagementDiscussionsList                                                                                        from "@/components/client/Engagement/ClientEngagementDiscussionsList.vue";
import { QLClient, QLSortOrder, useClientEngagementsQuery, useClientEngagementStatsQuery, useClientEngagementsTimelineQuery } from "@/graphql/queries/ql/composables";
import { computed, toRef }                                                                                                    from "vue";
import routesList                                                                                                             from "@/composables/routesList";

interface Props {
  client: QLClient;
}

const props = withDefaults(defineProps<Props>(), {});
const {routeList} = routesList();

const client = toRef(props, "client");

const clientEngagementStatsQ = useClientEngagementStatsQuery(() => ({id: client.value.id}));
const {loading: loadingStats} = clientEngagementStatsQ;
const engagementStats = computed(() =>
                                     clientEngagementStatsQ.result.value?.client_engagement_stats.engagements_stats ?? null
);

const totalEngagements = computed(() =>
                                      clientEngagementStatsQ.result.value?.client_engagement_stats.total_engagements ?? 0
);

const clientEngagementLastQ = useClientEngagementsQuery(() => ({
  client_id: client.value.id,
  page:      1,
  first:     1,
  orderBy:   [
    {
      column: "created_at",
      order:  QLSortOrder.DESC
    }
  ]
}));
const {loading: loadingLastEngagement} = clientEngagementLastQ;
const lastEngagement = computed(() =>
                                    clientEngagementLastQ.result.value?.client_engagements.data[0] ?? null
);

const timelineQ = useClientEngagementsTimelineQuery(() => ({
  client_id: client.value.id,
  orderBy:   [
    {
      column: "created_at",
      order:  QLSortOrder.DESC
    }
  ]
}));
const {loading: loadingTimeline} = timelineQ;
const timeline = computed(() =>
                              timelineQ.result.value?.client_engagements_timeline.data ?? []
);

</script>
