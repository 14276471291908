<template>
  <div>
    <timeframe-selector
        v-model:date="dateSelected"
        position="left"
        :min-date="automation.created_at" class="mb-4" />
    <div class="space-y-3 lg:space-y-6 ">
      <div class="grid lg:grid-cols-3 gap-3 lg:gap-6 grid-flow-row-dense">
        <div class="flex flex-col rounded-lg shadow overflow-hidden">
          <automation-stat-box-simple
              :current="automationStats.sent"
              :loading="loadingStats"
              :past="automationStatsPast.sent"
              :show-past="showPastData">
            <template #icon>
              <svg-icon
                  name="send"
                  class="w-5 h-5"
                  icon="email" />
            </template>
            <template #title>
              Emails Sent
            </template>
          </automation-stat-box-simple>
        </div>
        <div class="flex flex-col rounded-lg shadow overflow-hidden">
          <automation-stat-box-simple
              :current="automationStats.opened"
              :loading="loadingStats"
              :past="automationStatsPast.opened"
              :show-past="showPastData">
            <template #icon>
              <svg-icon
                  name="email"
                  class="w-5 h-5"
                  icon="email" />
            </template>
            <template #title>
              Emails Opened
            </template>
          </automation-stat-box-simple>
        </div>
        <div class="flex flex-col rounded-lg shadow overflow-hidden">
          <automation-stat-box-simple
              :current="automationStats.clicked"
              :loading="loadingStats"
              :past="automationStatsPast.clicked"
              :show-past="showPastData">
            <template #icon>
              <svg-icon
                  name="click"
                  class="w-5 h-5" />
            </template>
            <template #title>
              Emails Clicked
            </template>
          </automation-stat-box-simple>
        </div>
      </div>

      <div class="flex flex-col rounded-lg shadow overflow-hidden">
        <div class="bg-white p-3 py-5">
          <div class="px-2">
            <h3 class="font-semibold text-gray-600 mb-2">
              Delivery funnel
            </h3>
          </div>

          <div class="w-full block relative">
            <div
                v-if="loadingStats === true"
                class="h-96 w-full flex items-center justify-center">
              <loader class="w-8 h-8 text-gray-300" />
            </div>
            <automation-show-info-email-funnel
                v-if="loadingStats !== true"
                :stats="automationStats"
                class="h-96" />
          </div>
        </div>
      </div>

      <div class="grid lg:grid-cols-2 gap-3 lg:gap-6 grid-flow-row-dense">
        <div class="flex flex-col rounded-lg shadow overflow-hidden">
          <div class="flex items-center grow bg-white p-3 py-5">
            <automation-show-pie-chart
                :loading="loadingStats"
                :stats="openRateChartData"
                chart-label="Opened vs Unread">
              Open Rate
            </automation-show-pie-chart>
          </div>
        </div>

        <div class="flex flex-col rounded-lg shadow overflow-hidden">
          <div class="flex items-center grow bg-white p-3 py-5">
            <automation-show-pie-chart
                :loading="loadingStats"
                :stats="clickRateChartData"
                chart-label="Clicked vs Not clicked">
              Click Rate
            </automation-show-pie-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>

import AutomationShowInfoEmailFunnel                                          from "@/components/automations/Show/Infos/Email/Charts/AutomationShowInfoEmailFunnel.vue";
import TimeframeSelector                                                      from "@/components/layout/TimeframeSelector.vue";
import AutomationStatBoxSimple                                                from "@/components/layout/StatBoxSimple.vue";
import AutomationShowPieChart                                                 from "@/components/automations/Show/partials/AutomationShowPieChart.vue";
import Loader                                                                 from "@/components/layout/Loader.vue";
import { computed, toRef, useAttrs, useSlots }                                from "vue";
import { QLFragmentFullAutomationFragment, useAutomationStatEmailOrSmsQuery } from "@/graphql/queries/ql/composables";
import SvgIcon                                                                from "@/components/SvgIcon.vue";
import automationShowInfoDateSelection                                        from "@/components/automations/Show/Infos/automationShowInfoDateSelection";

interface Props {
  automation: QLFragmentFullAutomationFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const automation = toRef(props, "automation");

const infoDate = automationShowInfoDateSelection(automation);

const { showPastData, dateSelected } = infoDate;

const currentPeriod = useAutomationStatEmailOrSmsQuery(infoDate.queryVariablesCurrent, infoDate.queryOptionsCurrent);
const pastPeriod = useAutomationStatEmailOrSmsQuery(infoDate.queryVariablesPast, infoDate.queryOptionsPast);

const currentPeriodResult = computed(() => currentPeriod.result.value?.automation_stat_email_or_sms ?? null);
const pastPeriodResult = computed(() => pastPeriod.result.value?.automation_stat_email_or_sms ?? null);

const loadingStats = computed(() => {
  return currentPeriod.loading.value || pastPeriod.loading.value;
});

const automationStats = computed(() => {
  if (!currentPeriodResult.value) {
    return blankObject();
  }
  return currentPeriodResult.value;
});

function blankObject() {
  return {
    sent:      0,
    delivered: 0,
    bounced:   0,
    opened:    0,
    clicked:   0
  };
}

const automationStatsPast = computed(() => {
  if (!pastPeriodResult.value) {

    return blankObject();
  }
  return pastPeriodResult.value;
});

const openRateChartData = computed(() => {
  if (loadingStats.value === true) {
    return [];
  }

  return [
    { name: "Opened", cc: automationStats.value.opened },
    { name: "Unread", cc: automationStats.value.delivered - automationStats.value.opened }
  ];
});

const clickRateChartData = computed(() => {
  if (loadingStats.value === true) {
    return [];
  }
  return [
    { name: "Clicked", cc: automationStats.value.clicked },
    { name: "Not clicked", cc: automationStats.value.delivered - automationStats.value.clicked }
  ];
});

</script>
