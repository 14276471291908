<template>
  <div class="">
    <h3 class="font-semibold text-gray-600 mb-2">
      Event types breakdown
    </h3>
    <div
        v-if="loading"
        class="text-gray-500 flex items-center justify-center h-48"
        style="min-height: 6rem">
      <div class="h-8 w-8 ">
        <loader />
      </div>
    </div>
    <div
        v-else
        class="h-full relative">
      <pie-chart
          :items="chartData"
          class="h-48 relative w-full"
          item-name="name"
          item-value="cc"
          label="Event types breakdown" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { orderBy }                 from "lodash";
import PieChart                    from "@/components/layout/Charts/PieChart.vue";
import Loader                      from "@/components/layout/Loader.vue";
import { computed, toRef }         from "vue";
import { useClientClassTypeQuery } from "@/graphql/queries/ql/composables";
import { echoListener }            from "@/composables/echoListener";
import { EventTypes }              from "@/classes/notifications/EventTypes";

interface Props {
  clientId: string;
}

const props = withDefaults(defineProps<Props>(), {});

const clientId = toRef(props, "clientId");
const clientClassTypeQ = useClientClassTypeQuery({client_id: clientId.value});

const {loading, result} = clientClassTypeQ;

const clientClassTypes = computed(() => result.value?.client_class_types ?? []);

const chartData = computed(() => {
  return orderBy(clientClassTypes.value, ["cc"], ["desc"]);
});

echoListener([EventTypes.VisitWasUpdated], (ev) => {
  if (ev.data.client_id == clientId.value) {
    clientClassTypeQ.refetch();
  }
}, 2000);

</script>
