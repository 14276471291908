<template>
  <modal
      v-model:open="openModel"
      :has-close-button="!sending">
    <div class="px-4 py-4 w-full">
      <div class="text-xl text-green-700 font-medium">
        Assign this To-Do
      </div>
      <p class="text-sm text-gray-500 mt-2">
        Both automated and client specific To-Dos can be assigned to specific staff
        members and to a specific group of
        staff members. Assigned To-Dos will provide easier filtering on the To-Do
        page, as
        well as higher visibility to that staff member on their Dashboard
      </p>
      <form
          ref="form"
          class="block w-full mt-8"
          @submit.prevent="changeAssign">
        <div class="flex mb-4 items-center">
          <label
              class="form-label mr-4 w-1/5"
              for="assigned">
            Assigned to person
          </label>
          <div class="flex w-full">
            <select
                id="assigned"
                v-model="assigned_to_id"
                class="form-select form-input-default block w-full"
                name="assigned">
              <option :value="null">
                Everyone
              </option>
              <option
                  v-for="user in users"
                  :key="user.id"
                  :value="user.id">
                {{ user.name }}
              </option>
            </select>
          </div>
        </div>
        <div
            v-if="groups"
            class="flex mb-4 items-center">
          <label
              class="form-label mr-4 w-1/5"
              for="assigned">
            Assigned to group
          </label>
          <div class="flex w-full">
            <select
                id="group"
                v-model="assigned_to_group_id"
                class="form-select form-input-default block w-full"
                name="group">
              <option :value="null">
                Everyone
              </option>
              <option
                  v-for="teamGroup in groups"
                  :key="teamGroup.id"
                  :value="teamGroup.id">
                {{ teamGroup.name }}
              </option>
            </select>
          </div>
        </div>
      </form>
    </div>
    <template #buttons>
      <v-button
          :loading="loading || sending"
          class="modal-button modal-button-green"
          @click="changeAssign">
        Assign it
      </v-button>
    </template>
  </modal>
</template>
<script lang="ts" setup>

import Modal   from "@/components/layout/Modal.vue";
import VButton from "@/components/layout/VButton.vue";

import { computed, onMounted, ref, toRef, watch }                                           from "vue";
import { QLTodo, useCurrentTeamGroupsQuery, useCurrentTeamUsersQuery, useEditTodoMutation } from "@/graphql/queries/ql/composables";
import $toast                                                                               from "@/composables/toast";

interface Props {
  todo: QLTodo;
  open: boolean;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits([ "update:open", "assigned" ]);

const openModel = computed({
                             get: () => props.open,
                             set: (val) => emit("update:open", val)
                           });

const form = ref(null);

const assigned_to_id = ref<null | string>(null);
const assigned_to_group_id = ref<null | string>(null);

const sending = ref(false);

const passedTodo = toRef(props, "todo");
watch(passedTodo, () => {
  resetForm();
});
onMounted(() => {
  resetForm();
});

const resetForm = () => {
  assigned_to_id.value = passedTodo.value.assigned_to_id;
  assigned_to_group_id.value = passedTodo.value.team_group?.id ?? null;
};

const groupsQ = useCurrentTeamGroupsQuery();
const groups = computed(() => groupsQ.result.value?.current_team?.groups ?? []);

const usersQ = useCurrentTeamUsersQuery();
const users = computed(() => usersQ.result.value?.current_team?.users ?? []);

const loading = computed(() => {
  return groupsQ.loading.value || usersQ.loading.value;
});

const mutation = useEditTodoMutation({});
mutation.onError(() => {
  $toast({
           type:         "error",
           message:      "Error updating the To-Do. Please try again!",
           linkRouteObj: null,
           linkText:     null
         });
  sending.value = false;
});

mutation.onDone(() => {
  $toast({
           type:         "success",
           message:      "To-Do assigned!",
           linkText:     null,
           linkRouteObj: null
         });
  sending.value = false;
  openModel.value = false;
  emit("assigned");
  resetForm();
});

const changeAssign = () => {
  if (!form.value.reportValidity()) {
    return;
  }
  if (sending.value === true) {
    return;
  }
  sending.value = true;

  mutation.mutate({
                    id:             passedTodo.value.id.toString(),
                    message:        passedTodo.value.message,
                    subject:        passedTodo.value.subject,
                    priority_type:  passedTodo.value.priority,
                    overdue_at:     passedTodo.value.overdue_at,
                    postponed_to:   passedTodo.value.postponed_to,
                    assigned_to_id: assigned_to_id.value,
                    team_group_id:  assigned_to_group_id.value
                  });
};
</script>
