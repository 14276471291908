<template>
  <page-title title="Catalog" section="Automations" :sub-text="!loading ? catalogItem.description: null">
    <template #title>
      <loader
          v-if="loading"
          class="w-10 h-10 text-gray-400" />
      <div v-if="!loading">
        Catalog: {{ catalogItem.name }}
      </div>
    </template>
  </page-title>
  <div class="container mx-auto grow">
    <router-link
        :to="{name: routeList.automations.catalog.index}"
        class="text-link text-sm text-blue-900 inline-flex items-center mb-6">
      <svg-icon
          name="left"
          class="h-4 w-4" />
      <div>Back to the catalog list</div>
    </router-link>
    <div
        v-if="loading"
        class="w-full h-48 flex items-center justify-center">
      <loader class="w-10 h-10 text-gray-400" />
    </div>

    <div
        v-if="!loading"
        class="md:flex md:space-x-8">
      <div class="md:w-1/3 ">
        <div class="bg-white px-6 p-4 rounded-lg shadow prose">
          <div v-html="catalogItem.long_description" />
        </div>
        <div
            v-if="canEditCatalog"
            class="pt-8">
          <router-link
              :to="{name: routeList.automations.catalog.edit, params:{id: id}}"
              class="text-link">
            Edit catalog
          </router-link>
        </div>
      </div>
      <div class="grow ">
        <h3 class="text-gray-700">
          Your automation is almost ready!
        </h3>
        <div class="text-gray-700 text-sm mb-4">
          <p>
            Please fill out the information below and, click "Add to my account" to save the automation in your
            automations list. Your new
            automation will remain inactive until manually activated.
          </p>
        </div>
        <div class="bg-white rounded-lg shadow-xl">
          <div class="p-6 space-y-6">
            <text-input
                v-model="automationName"
                data-name
                label="Name"
                placeholder="Example: Trial Offer - Expiring in 3 days - SMS"
                required>
              <div class="leading-tight form-small-info text-gray-600">
                Use a name that is easy to understand the purpose of the automation and audience segment (eg - Package
                is
                about to expire - Text message)
              </div>
            </text-input>

            <div
                v-if="catalogItem.automation.delivery_type === 'TODO'"
                class="">
              <label class="form-label ">
                Assigned to person
              </label>

              <select
                  id="assigned"
                  v-model="todoAssignedId"
                  class="mt-1 form-select form-input-default block w-full"
                  name="assigned">
                <option :value="null">
                  Everyone
                </option>
                <option
                    v-for="user in users"
                    :key="user.id"
                    :value="parseInt(user.id)">
                  {{ user.name }}
                </option>
              </select>
              <p class="text-xs pt-1 text-gray-600">
                Optional: assign the todos generated by this automation to a
                specific staff member
              </p>
            </div>

            <catalog-user-variable-input
                v-for="varField in catalogItem.variable_fields"
                :key="varField.id"
                :variable-input="varField"
                :model-value="variableData(varField.id)"
                @update:model-value="setVariableData(varField.id, $event)"
                @is-valid="variableIsValid(varField.id,$event)" />

            <div class="flex justify-end">
              <v-button
                  class="btn btn-green"
                  :disabled="!buttonEnabled"
                  @click="save">
                Add to my account
              </v-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import PageTitle                                                                                                                        from "@/components/navbar/PageTitle.vue";
import Loader                                                                                                                           from "@/components/layout/Loader.vue";
import TextInput                                                                                                                        from "@/components/layout/Forms/TextInput.vue";
import CatalogUserVariableInput                                                                                                         from "@/components/automations/Catalog/CatalogUserVariableInput.vue";
import VButton                                                                                                                          from "@/components/layout/VButton.vue";
import SvgIcon                                                                                                                          from "@/components/SvgIcon.vue";
import { QLMutationAddCatalogToAutomationsArgs, useAddCatalogToAutomationsMutation, useCatalogItemFullQuery, useCurrentTeamUsersQuery } from "@/graphql/queries/ql/composables";
import meQuery                                                                                                                          from "@/composables/queries/meQuery";
import { computed, ref }                                                                                                                from "vue";
import { deepCopy }                                                                                                                     from "@/classes/helpers/DeepCopy";
import userMeDecorator                                                                                                                  from "@/composables/decorators/userMeDecorator";
import routeList                                                                                                                        from "@/router/Routes";
import $toast                                                                                                                           from "@/composables/toast";
import routesList                                                                                                                       from "@/composables/routesList";
import { useRouter }                                                                                                                    from "vue-router";

const router = useRouter();


interface Props {
  id: string;
}

const props = withDefaults(defineProps<Props>(), {});

const saving = ref(false);

const automationNameInt = ref(null);
const todoAssignedId = ref(null);
const variablesData = ref({});
const validVariables = ref([]);

const usersQuery = useCurrentTeamUsersQuery();
const users = computed(() => usersQuery.result.value?.current_team.users ?? []);

const {me} = meQuery();

const getCatalog = useCatalogItemFullQuery({id: props.id});
const {loading} = getCatalog;

const catalogItem = ref(null);


getCatalog.onResult((res) => {
  if (!res.loading) {
    catalogItem.value = deepCopy(res.data.catalog_item);
  }
});


const variableData = (id) => {
  if (variablesData.value.hasOwnProperty(id)) {
    return variablesData.value[id];
  }
  return null;
};

const setVariableData = (id, val) => {
  let newVars = {...variablesData.value};
  newVars[id] = val;

  variablesData.value = newVars;
};

const variableIsValid = (id, valid) => {
  const withoutId = validVariables.value.filter((i) => i !== id);
  if (!valid) {
    validVariables.value = withoutId;
  } else {
    withoutId.push(id);
    validVariables.value = withoutId;
  }
};

const isValidVariable = (id) => {
  return validVariables.value.indexOf(id) !== -1;
};

const automationName = computed({
                                  get: () => {
                                    if (!catalogItem.value) {
                                      return "";
                                    }
                                    if (automationNameInt.value !== null) {
                                      return automationNameInt.value;
                                    }
                                    return catalogItem.value.automation.name;
                                  },
                                  set: (val) => automationNameInt.value = val
                                });

const canSave = computed(() => {
  if (!automationName.value || automationName.value.length === 0) {
    return false;
  }
  if (catalogItem.value.variable_fields.length === 0) {
    return true;
  }

  if (validVariables.value.length !== catalogItem.value.variable_fields.length) {
    return false;
  }

  if (Object.keys(variablesData.value).length !== catalogItem.value.variable_fields.length) {
    return false;
  }


  return catalogItem.value.variable_fields.map((varItem) => {
    return isValidVariable(varItem.id) && variableData(varItem.id) !== null;
  }).indexOf(false) === -1;
});

const buttonEnabled = computed(() => {
  return canSave.value && saving.value === false;
});

const canEditCatalog = computed(() => {
  if (!me.value) {
    return false;
  }
  return userMeDecorator(me).methods.hasPermission("manage catalog");
});


const mut = useAddCatalogToAutomationsMutation({});
mut.onError(() => {
  $toast({
           type:         "error",
           message:      "There was an error while creating the automation. Please check the information and try again",
           linkRouteObj: null,
           linkText:     null
         });
});

mut.onDone((d) => {
  saving.value = false;
  const finalRoute = {
    name:   routesList().routeList.automations.show.index,
    params: {id: d.data.AddCatalogToAutomations.id}
  };

  $toast({
           type:    "success",
           message: "The automation has been created!"
         });

  router.push(finalRoute);
});

const save = () => {
  saving.value = true;
  const dataToSend: QLMutationAddCatalogToAutomationsArgs = {
    catalog_id:   catalogItem.value.id,
    catalog_data: {
      automation_name:     automationName.value,
      todo_assigned_to_id: todoAssignedId.value,
      variables:           catalogItem.value.variable_fields.map((varItem) => {
        return {
          id:    varItem.id,
          value: variableData(varItem.id)
        };
      })
    }
  };

  mut.mutate(dataToSend);
};

</script>
