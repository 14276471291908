<template>
  <div>
    <div class="space-y-4">
      <div class="text-right text-xs" v-if="currentTeam.pm_last_four">
        ****-****-****-{{ currentTeam.pm_last_four }}
      </div>
      <div>
        <text-input v-model="cardHolderName"
                    autocomplete="name"
                    label="Cardholder's Name"
                    name="name"
                    required type="text" />
      </div>
      <div>
        <label class="form-label" v-bind:class="{'has-error': errors.has('card')}">Credit Card</label>
        <div id="card-element-update-billing"></div>
        <span v-if="errors.has('card')"
              class="form-error-message">{{ errors.get("card") }}</span>
      </div>
      <div class="pt-4">
        <v-button :disabled="sending" :loading="sending" class="btn btn-white" @click="updateCard">Update payment
                                                                                                   method
        </v-button>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>

import {computed, onMounted, ref}                                      from "vue";
import VButton                                                         from "@/components/layout/VButton.vue";
import SparkFormErrors, {SparkErrors}                                  from "@/classes/utils/SparkFormErrors";
import getStripeCardToken                                              from "@/composables/billing/stripeCardToken";
import {QLFragmentCurrentTeamFragment, useUpdatePaymentMethodMutation} from "@/graphql/queries/ql/composables";
import $toast                                                          from "@/composables/toast";
import TextInput                                                       from "@/components/layout/Forms/TextInput.vue";
import fillValidationErrorsOrToast                                     from "@/composables/queries/mutations/fillValidationErrorsOrToast";


interface Props {
  currentTeam: QLFragmentCurrentTeamFragment;
}

const props = withDefaults(defineProps<Props>(), {});
const emit = defineEmits(['updated']);
const sending = ref(false);

const errorsData = ref<SparkErrors>({});
const errors = computed(() => {
  return new SparkFormErrors(errorsData.value);
});

const {
  cardHolderName,
  getPaymentMethod,
  onMounted: stripeOnMounted
} = getStripeCardToken("#card-element-update-billing", errorsData);


onMounted(async () => {
  await stripeOnMounted();
});

const mutation = useUpdatePaymentMethodMutation({});

mutation.onError((err) => {
  fillValidationErrorsOrToast(err, errorsData, "There was a problem updating your payment method. Please try again");
  sending.value = false;
});

mutation.onDone(() => {
  $toast({
           type:         "success",
           message:      "Payment method updated",
           linkText:     null,
           linkRouteObj: null
         });
  sending.value = false;
  emit('updated');
});


const updateCard = async () => {
  errorsData.value = {};
  sending.value = true;

  try {
    const paymentMethod = await getPaymentMethod();
    await mutation.mutate({
                            payment_method: paymentMethod
                          });
  } catch (e) {
    $toast({
             type:         "error",
             message:      "Error while updating payment method. Please try again",
             linkText:     null,
             linkRouteObj: null
           });
    sending.value = false;
    return;
  }
};
</script>
