<template>
  <div>
    <loader v-if="loading" class="w-4 h-4 text-gray-600"></loader>
    <smart-select v-if="!isMultiple"
                  v-model="selectedValue"
                  :items="items"
                  focus-on-show>
    </smart-select>
    <smart-select-tags v-if="isMultiple"
                       :allow-new="false"
                       :items="items"
                       v-model="selectedValue"
                       class="block w-full"
                       focus-on-show
    >
    </smart-select-tags>
  </div>
</template>
<script lang="ts" setup>

import SmartSelectTags                              from "@/components/layout/Forms/SmartSelectTags.vue";
import SmartSelect                                  from "@/components/layout/Forms/SmartSelect.vue";
import { OperatorTypes }                            from "hm-filters/operators/OperatorTypes";
import Loader                                       from "@/components/layout/Loader.vue";
import { computed, onMounted, ref, toRef, watch }   from "vue";
import GraphQlOperator, { GraphQlMultipleOperator } from "hm-filters/operators/GraphQlOperator";
import { useQuery }                                 from "@vue/apollo-composable";

interface Props {
  operator: GraphQlOperator | GraphQlMultipleOperator;
  modelValue: any;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits(["update:modelValue", "is-valid"]);
const modelValue = toRef(props, "modelValue");
const operator = toRef(props, "operator");

const isMultiple = computed(() => {
  return operator.value.type === OperatorTypes.GraphQlMultiple;
});
const options = ref({
                      enabled: false
                    });

const {result, loading, onResult} = useQuery(operator.value.graphQLData.query, {}, options);
const qlReturn = computed(() => result.value?.[operator.value.graphQLData.queryPath] ?? []);


const items = computed(() => {
  if (!qlReturn.value) {
    return [];
  }
  return qlReturn.value.map(item => item.name);
});

const selectedValue = computed({
                                 get: () => {
                                   if (!modelValue.value) {
                                     return [];
                                   }
                                   return modelValue.value;
                                 },
                                 set: (newVal) => emit("update:modelValue",
                                                       newVal !== null ? newVal : null)
                               });


const isValidCalculation = () => {
  let valid = modelValue.value !== null && modelValue.value.length > 0;
  emit("is-valid", valid);
  return valid;
};

const isValid = computed(() => {
  return isValidCalculation();
});

onMounted(() => {
  options.value.enabled = true;

});

watch(modelValue, isValidCalculation);
</script>
